import React, { useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import CheckIcon from '@mui/icons-material/Check';
import { countries } from '../../utils/countryList';
import { languages } from '../../utils/languageList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledSelect = styled(Select)`
  & :nth-child(1) {
    cursor: pointer;
  }
`;

const Label = styled.span`
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;
  text-align: left;
  color: #000000;
`;

const SubLabel = styled.span`
  color: #636363;
  display: flex;
  height: 20px;
  font-size: 14px;
  margin-left: 8px;
  font-family: Montserrat;
`;

const SelectWrapper = styled.div`
  position: relative;

  & > :last-child {
    position: absolute;
    top: 30%;
    right: 2%;
    pointer-events: none;
  }
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: '15px',
    border: '1px solid #A2A2A2',
    marginLeft: '0px',
    background: '#FFF',
    minHeight: '54px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#636363',
    fontSize: '16px',
    textAlign: 'left',
    marginLeft: '10px',
    fontFamily: 'Montserrat',
    //fontWeight:'500',
  }),
  option: (provided, state) => ({
    ...provided,
    width: '1518px',
    fontSize: '16px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#636363',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#F2F2FE',
    borderRadius: '40px',
    width: '90px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#636363',
  }),
};

const MultipleCountriesSelect = ({
  label,
  placeholder,
  subLabel,
  onChange,
  language,
  value,
  setValue
}) => {
  const optionStyle = {
    marginLeft: '5px',
    color: '#707070',
    fontSize: '16px',
    fontWeight: '500',
    marginTop: '12px',
  };

  const IconStyle = {
    width: '25px',
    height: '25px',
    border: '1px solid rgba(152, 152, 152, 0.24)',
    margin: '8px 8px 0px 8px',
    borderRadius: '50%',
    color: '#fff',
    background: '#fff',

    /*  ${({ active }) => active === "false" && "background: #fff"}
  `; */
  };

  const [selectedCountries, setSelectedCountries] = useState([]);

  const handleSelect = (event) => {
    const selectedOptions = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedCountries(selectedOptions);
  };
  const handleChange = (selectedCountries) => {
    setValue(selectedCountries || []);
    // setSelectedCountries(selectedCountries || []);
    // onChange(selectedCountries);
  };
  

  const Option = ({ children, isSelected, innerProps }) => (
    <div style={{ textAlign: 'left', display: 'flex' }} {...innerProps}>
      <CheckIcon active={value} style={IconStyle} />
      <span style={optionStyle}>{children}</span>
    </div>
  );

  return (
    <>
      {label && <Label>{label}</Label>}
      {subLabel && <SubLabel>{subLabel}</SubLabel>}
      <SelectWrapper>
       
          <StyledSelect
          options={language}
          onChange={handleChange}
          value={value}
          isMulti
          styles={customStyles}
          components={{ Option }}
          placeholder={placeholder}
        />

        <KeyboardArrowDownIcon />
      </SelectWrapper>
    </>
  );
};

export default MultipleCountriesSelect;
