import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

const DateSlider = ({startDate, endDate, onChange, topicCount}) => {
  // const getMonthsBetweenDates = (startDate, endDate) => {
    const start = new Date(startDate?.split('/').reverse().join('-'));
    const end = new Date(endDate?.split('/').reverse().join('-'));
    const months = [];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    while (start <= end) {
        const month = monthNames[start.getMonth()];
        const year = start.getFullYear();
        months.push(`${month} ${year}`);
        start.setMonth(start.getMonth() + 1);
    }

  // const step = 0.5; // Allow in-between selections
  const max = (months.length - 1) ;

  const [value, setValue] = useState([0 , max ]); 

  const handleChange = (event, newValue) => {
    const start = months[newValue[0]].split(' ')
    const end = months[newValue[1]].split(' ')
    const startMonth = monthNames.indexOf(start[0] + 1) < 9 ? 0+`${monthNames.indexOf(start[0]) +1}` :monthNames.indexOf(start[0]) +1
    const endMonth = monthNames.indexOf(end[0]) < 9 ? 0+`${monthNames.indexOf(end[0])+1}` : monthNames.indexOf(end[0])+1
    onChange({startDate : `01/${startMonth}/${start[1]}`, endDate: `01/${endMonth}/${end[1]}`, topicCount:topicCount})
    setValue(newValue);
  };

  const getMonthLabel = (index) => {
    const wholeIndex = Math.floor(index);
    const fraction = index % 1;
    return fraction === 0 ? months[wholeIndex] : `${months[wholeIndex]} - Mid`;
  };

  return (
    <Box
      sx={{
        width: '90%',
        padding: '20px',
        margin: '0 auto', // Centering the slider horizontally
        textAlign: 'center', // Centering the content inside the box
      }}
    >
      <Slider
        getAriaLabel={() => 'Date range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        min={0}
        max={max}
        // step={step}
        valueLabelFormat={getMonthLabel}
        marks={months.map((month, index) => ({
          value: index ,
          label: month,
        }))}
        sx={{
          '& .MuiSlider-track': {
            bgcolor: '#3f51b5',
            height: '20px', // Making the track wider
          },
          '& .MuiSlider-thumb': {
            bgcolor: 'white',
            border: '2px solid #A2A2A2',
            height: '30px', // Making the thumb larger to match the track size
            width: '30px',
          },
          '& .MuiSlider-rail': {
            bgcolor: '#c9c4c4',
            height: '20px', // Matching the rail height to the track
            borderTop: '1px solid black', // Adding the top border
            borderBottom: '1px solid black', // Adding the bottom border
            borderRadius: 0,

          },
          '& .MuiSlider-markLabel': {
            color: '#555',
            fontSize: '14px',
            marginTop:'10px',
          },
          '& .MuiSlider-mark': {
            display: 'none', // Hiding the dots between the months
          },
        }}
      />
    </Box>
  );
};

export default DateSlider;
