import React, { useState } from 'react';
import styled from 'styled-components'
import PostsReport from './PostsReport';
import SentinelHub from './SentinelHub';


const Wrapper = styled.div`
  height: calc(100vh - 60px);
  overflow: hidden;
`;

const TabWrapper = styled.div`
position: relative;
    display: flex;
    flex-direction: row;
    gap: 25px;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid rgba(99, 99, 99, 0.25);
    padding-bottom: 10px;
    margin: auto auto 10px;
    padding: 10px 0px;
    height: 45px;
    align-items: end;
    padding-bottom: 15px;


    & .tabsText {
        
    margin: 0px 20px 0px 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Open Sans';
    cursor:pointer;
    }

    & .active {
        color: rgba(53, 60, 247, 1);

    }
`;
const TabIndicator = styled.div`
  height: 3px;
  background-color: rgb(53 60 247); /* Change color as needed */
  transition: transform 0.3s ease;
  position: absolute;
  bottom: 0;
  width: 100px;
`;



const SentineHubHome = () => {
    const [showReports, setShowReports] = useState(true)
  const [activeTab, setActiveTab] = useState(0);

  const handleShowTabs = (val,index) =>{
    setActiveTab(index)
    setShowReports(val)
  }

    return (
        <Wrapper>
            <TabWrapper>
                <h3 className={showReports ? 'tabsText active' :'tabsText' } key={0} onClick={() => handleShowTabs(true,0)}>Reports</h3>
                <h3 className={showReports == false ? 'tabsText active' :'tabsText' } key={1} onClick={() => handleShowTabs(false,1)}>Posts</h3>
                <TabIndicator style={{ transform: `translateX(${activeTab * 100}%) scaleX(1)` }} />
     
            </TabWrapper>
            {
                showReports ?
                    <SentinelHub />
                    :
                    <PostsReport />
            }
        </Wrapper>
    );
};

export default SentineHubHome;