import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  productDetailFlipkart,
  productDetailList,
} from '../../../../../store/redux/slices/chatterSlice';
import {
  AmazonIcon,
  CrossIconWhite,
  SearchIcon,
  FlipkartLogo,
} from '../../../../../Common/SvgIcon/CommonSvgIcons';
// import FlipkartPlus from '../../../../../../src/assests/images/flipkartPlus.png' 
import ProductDetails from './ProductDetails';
import CountrySelect from './CountriesSelect';

// Styled components
const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the popup is on top of other content */
`;

const PopupContainer = styled.div`
  width: 90%; /* Adjust the width as needed */
  background-color: white;
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: -70px;
  /*  overflow-y: scroll; */
`;

const PopupHeader = styled.div`
  display: flex;
  background-color: ${(pop) =>
    pop.selectedChannel === 'Flipkart' ? '#353cf7' : '#353cf7'};
  justify-content: space-between;
  border-bottom: ${(pop) =>
    pop.selectedChannel === 'Flipkart' ? '1px solid #2222' : 'none'};
  align-items: center;
  height: 40px;
  /* color: white; */
  padding: 10px;
  border-radius: 0px;
`;

const IconSpan = styled.span`
  //display:flex;
  margin: 10px 0px 0px 35px;
`;

const CloseIcon = styled.span`
  //float:right;
  margin-right: 15px;

  & > svg {
    fill: blue;
    stroke: ${(pop) => (pop.selectedChannel === 'Flipkart' ? 'none' : 'none')};
  }
`;

const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: scroll;
  height: ${(props) => (props.expanded ? '500px' : '180px')};

  span {
    display: flex;
    margin-left: 25px;

    color: #636363;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  margin-left: -40px;
  margin-bottom: 70px;

  span {
    position: relative;
    left: 50px;
    top: 45%;
  }
`;

const Button = styled.button`
  cursor: pointer;
  width: 170px;
  height: 50px;
  padding: 7px 21px;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 0px 20px;
  color: white;

  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;

  border-radius: 100px;
  background: #353cf7;
  border: none;
`;

const Input = styled.input`
  margin: 10px 0px 0px 20px;
  padding-left: 40px;
  width: 50%;
  height: 45px;
  border-radius: 15px;
  border: 1px solid #a2a2a2;

  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

const Line = styled.div`
  margin-top: 25px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
  background: #dddddd;
`;

// Component
const PoPup = ({ isOpen, onClose, onProductSelect, selectedChannel }) => {
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState('');
  const [productList, setProductList] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');

  const countries = [
    { id: 'us', name: 'United States', flag: 'us.png' },
    { id: 'in', name: 'India', flag: 'ca.png' },
    { id: 'uk', name: 'United Kingdom' },
    { id: 'ca', name: 'Canada', flag: 'ca.png' },
    { id: 'au', name: 'Australia', flag: 'au.png' },
  ];

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };

  const productDetailsData =
    useSelector((state) => state.chatterTracker.productDetailsData) || [];

  const productListFlipkart =
    useSelector((state) => state.chatterTracker.productListFlipkart) || [];
  const loading = useSelector((state) => state.chatterTracker.isLoading);


  if (!isOpen) {
    return null;
  }

  const handleProductClick = (product) => {
    onProductSelect(product); // Call the function passed from ChatterTrackerBuilder
    onClose(); // Close the popup
  };

  const handleSearch = () => {
    if (selectedChannel === 'Amazon') {
      dispatch(productDetailList({ searchQuery, selectedCountry }));
    } else {
      dispatch(productDetailFlipkart({ searchQuery, selectedCountry: 'India'}));
    }

    setExpanded(true);
  };

  return (
    <PopupOverlay>
      <PopupContainer>
        <PopupHeader selectedChannel={selectedChannel}>
          <IconSpan>
            {selectedChannel === 'Amazon' ? AmazonIcon : FlipkartLogo }
          </IconSpan>
          <CloseIcon selectedChannel={selectedChannel} onClick={onClose}>
            {CrossIconWhite}
          </CloseIcon>
        </PopupHeader>
        <PopupBody expanded={expanded}>
          <span>Search for the Product</span>
          <SearchWrapper>
            <span>{SearchIcon}</span>
            <Input
              data-testid='your-input-test-id'
              type='text'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            ></Input>
            {selectedChannel === 'Amazon' ? (
              <CountrySelect
                countries={countries}
                onSelect={handleCountrySelect}
              />
            ) : (
              ''
            )}

            <Button onClick={handleSearch}>Search</Button>
          </SearchWrapper>
          {expanded && (
            <div style={{ height: 'auto' }}>
              <Line />
              <ProductDetails
                productList={
                  selectedChannel === 'Amazon'
                    ? productDetailsData
                    : productListFlipkart
                }
                isOpen={isOpen}
                onClose={onClose}
                onProductClick={handleProductClick}
                onLoading={loading}
              />
            </div>
          )}
        </PopupBody>
      </PopupContainer>
    </PopupOverlay>
  );
};

export default PoPup;
