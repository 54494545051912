import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import Loader from '../../../../../assests/animation/Loader2.json';
import {
  DownArrowIcon,
  FilledStar,
  HalfStar,
} from '../../../../../Common/SvgIcon/CommonSvgIcons';

const Wrapper = styled.div`
  width: 100%;
  // height: 100vh;
  overflow-y: scroll;
`;

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;

const ProdItem = styled.div`
  cursor:pointer;
  display: flex;
  height: 190px;
  /* border: 1px solid #ddd; */
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
`;

const Image = styled.img`
  border: 1px solid #ddd;
  width: 150px;
  height: 190px;
`;

const DetailsWrapper = styled.div`
  margin: 0px 0px 0px 30px;
  text-align: left;

  h3 {
    height: 25px;
    margin-left: 25px;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    justify-content: center;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 25px;
`;

const RatingSpan = styled.span`
 gap:6px;
 margin-top:40px;
`;

const Price = styled.p`
  height: 25px;
  font-family: Montserrat;
  font-size: 21px;
  font-style: normal;
  margin-right: 10px;
  font-weight: 500;
`;

const Line = styled.div`
  margin-top: 25px;
  margin-bottom:5px;
  height: 1px;
  width:100%;
  background: #dddddd;
`;

const RealPrice = styled.p`
  margin-top: 25px;
  color: #565959;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  text-decoration: line-through !important;
`;


function ProductDetails({ productList, onClose, onProductClick, onLoading }) {
  const [prodList, setProdList] = useState([]);
  

  useEffect(() => {
  /*  const filteredProducts = productList.filter((product) => {
      return (
        product.title !== null &&
        product.price !== null &&
        product.rating !== null &&
        product.rating_count !== null &&
        product.thumbnail_url !== null 
      );
    });

 */
    if (productList.length > 0) {
      setProdList(productList);
    }
  }, [productList]);

  const handleProductClick = (product) => {
    onProductClick(product);
    setProdList([]);
    onClose();
  };

  return (
    <Wrapper>
      {onLoading ? (
        <div  >
          <Lottie
            data-testid="loader"
            animationData={Loader}
            loop={true}
            // width='5'
            // height='5'
            style={{ height: '300px' }}
          />
        </div>
      ) : (
        <ProductList>
          {prodList.map((product) => (
            <>
            <ProdItem key={product.asin}>
              <Image src={product.thumbnail_url}></Image>
              <DetailsWrapper onClick={() => handleProductClick(product)}>
                <h3>
                    {product.title}
                </h3>
                <RatingSpan>
                  {product.rating} {FilledStar} {DownArrowIcon}
                  <span style={{ margin: '0px', color: '#007185' }}>
                    {product.rating_count}
                  </span>
                </RatingSpan>
                <PriceWrapper>
                  <Price style={{ color: '#B12704' }}> {product.price}</Price>
                  <RealPrice>
                    {product.real_price ? product.real_price : ''}
                  </RealPrice>
                </PriceWrapper>
                {/* <Description>
                  7.00 cm (6.7-inch) Super Retina XDR display featuring
                  Always-On and ProMotion Dynamic Island, a magical new way to
                  interact with iPhone{' '}
                </Description> */}
              </DetailsWrapper>
            </ProdItem>
            <Line /> 
            </>          
          ))}
        </ProductList>
      )}
    </Wrapper>
  );
}

export default ProductDetails;
