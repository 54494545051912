import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './alertMessageSlice';

import TenantAuthService from '../services/tenantAuth.service';

const user = () => JSON.parse(localStorage.getItem('brandUser')) || false;

export const loginTenant = createAsyncThunk(
  'tenantAuth/login',
  async ({ username, password, tenantName }, thunkAPI) => {
    try {
      console.log(tenantName,"tenantName");
      const data = await TenantAuthService.loginTenant(
        username,
        password,
        tenantName
        // window.location.pathname.split('/')[1]
      );
      localStorage.setItem('brandUser', JSON.stringify(data));
      return { ...data };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logoutTenant = createAsyncThunk(
  'tenantAuth/logout',
  async (thunkAPI) => {
    try {
      await TenantAuthService.logoutTenant();
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const tenantChangePassword = createAsyncThunk(
  'tenantAuth/changePassword',
  async ({ currentPassword, newPassword }, thunkAPI) => {
    try {
      let currentUser = user();
      const data = await TenantAuthService.tenantChangePassword(
        currentPassword,
        newPassword,
        currentUser.user_details.user_id,
        currentUser.tenant_id
      );
      currentUser.access_token = data.access_token;
      localStorage.setItem('user', JSON.stringify(currentUser));
      thunkAPI.dispatch(setMessage('Password change successful'));
      return { ...data };
    } catch (error) {
      const message =
        error.response.data.detail || 'Something went wrong, Try again';
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const tenantResetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ token, new_password }, thunkAPI) => {
    try {
      const data = await TenantAuthService.tenantResetPassword(
        new_password,
        token,
        window.location.pathname.split('/')[1]
      );
      thunkAPI.dispatch(setMessage(data.message));
      return { ...data };
    } catch (error) {
      const message =
        error.response.data.detail || 'Something went wrong, Try again';
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const tenantForgotPassword_api = createAsyncThunk(
  'auth/forgotPassword',
  async (email, thunkAPI) => {
    try {
      const data = await TenantAuthService.tenantForgotPassword(
        email,
        window.location.pathname.split('/')[1]
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await TenantAuthService.logout();
});

const initialState = {
  user: user().user_details,
  tenant_name: null,
  isLoggedIn: user().access_token ? true : false,
};

const tenantAuthSlice = createSlice({
  name: 'tenantAuth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginTenant.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.tenant_name = action.payload?.tenant_name;
        state.user = action.payload.user_details;
      })
      .addCase(loginTenant.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.tenant_name = null;
        state.user = null;
      })
      .addCase(tenantChangePassword.fulfilled, (state, action) => {
        console.log('Tenant Change password successful');
      })
      .addCase(tenantChangePassword.rejected, (state, action) => {
        console.log('Rejected');
      });
  },
});

export default tenantAuthSlice.reducer;
