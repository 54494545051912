import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import Pagination from '../Pagination/Pagination';
import { sort_icon } from '../../Common/SvgIcon/CommonSvgIcons';
import { useEffect } from 'react';
import CollapsibleText from '../ReviewText/collapsibleText';

const Wrapper = styled.div`
  // margin: 50px 25px;
  height: 100%;
  /* width: 100%; */
  .header {
    color: #636363;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;

    & > div:nth-child(2),
    & > div:nth-child(3) {
      color: #636363;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
  }
`;

const RowData = styled.div`
  display: grid;
  grid-template-columns: 45% 1fr 1fr 1fr;
  gap: 5px;
  text-align: left;

  color: #2a2929;
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  word-wrap: break-word;

  & .review {
    padding-left: 15px;

    & > div:nth-child(1) {
      color: #2a2929;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  & > div:nth-child(2),
  & > div:nth-child(3) {
    color: #000;
    font-size: 15px;
    font-weight: 600;
  }

  & > div:not(:first-child) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .filterColumn {
    cursor: pointer;
  }

  & .profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: Open Sans;
    font-size: 15px;
    font-weight: 600;

    & :last-child {
      color: #636363;
    }
  }
`;

const RowWrapper = styled.div`
  & > div {
    width: 100%;
    min-height: 120px;
    box-sizing: border-box;
    margin-top: 15px;
    border-radius: 15px;
    border: 1px solid #d9d9d9;
    padding: 20px 0;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 30px;

  margin-bottom: 30px;

  font-size: 15px;
  font-weight: 600;

  & > :first-child {
    box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
    padding: 8px 7px 5px 7px;
    border-radius: 10px;

    & svg {
      width: 30px;
      height: 30px;
    }
  }

  & input {
    box-shadow: 0px 0px 8px 0px #a2a2a23d;
    border: 1px solid #d9d9d9;
    margin-top: 5px;
    margin-left: -3px;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    color: #636363;

    padding: 5px 20px;
    border-radius: 5px;
  }
`;

const CommonMessagesTab = ({
  download,
  reviews = [],
  filterCol = [2, 3, 4],
  columns = [],
  PageSize = 8,
  // startDate,
  // endDate,
  graphStyles = {
    gridTemplateColumns: '45% 1fr 1fr 1fr'
  },
  channel
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsData, setReviewsData] = useState([]);
  // const [dateRange, setdateRange] = useState({
  //   startDate: startDate,
  //   endDate: endDate,
  // });

  useEffect(() => {
    setReviewsData([...reviews]);
  }, [reviews]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (download) {
      return reviewsData
    } else {

      return reviewsData.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, reviewsData]);

  const formatDate = (dateString) => {
    try {
      const parts = dateString.split('/');

      const formattedDate = `${parts[2]}-${parts[1].padStart(
        2,
        '0'
      )}-${parts[0].padStart(2, '0')}`;

      const date = new Date(formattedDate);
      const options = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      };
      return date.toLocaleDateString('en-US', options);
    } catch (error) {
      return '';
    }
  };

  function formatNumber(val) {
    if (val >= 1000000) {
      return (val / 1000000).toFixed(1) + 'M'; // Convert to million
    } else if (val >= 1000) {
      return (val / 1000).toFixed(1) + 'k';
    } else if (val > 0) {
      return val.toString();
    } else {
      return '';
    }
  }

  const handleFilter = (index) => {
    const tempReviews = [...reviewsData];
    /* tempReviews.sort(
      (a, b) =>
        b[columns[index].toLowerCase()] - a[columns[index].toLowerCase()]
    ); */
    if (columns[index] === 'Profile') {
      tempReviews.sort((a, b) => {
        const profileA = a.user_name || a.Name || '';
        const profileB = b.user_name || b.Name || '';
        return profileA.localeCompare(profileB);
      });
    } else {
      tempReviews.sort(
        (a, b) =>
          b[columns[index].toLowerCase()] - a[columns[index].toLowerCase()]
      );
    }
    setReviewsData([...tempReviews]);
  };
  const getDetails = (channel, message) => {
    switch (channel) {
      case 'twitter':
        return formatNumber(message?.engagement) || 0;
      case 'facebook':
        return formatNumber(message?.comments) || 0;
      case 'instagram':
        return formatNumber(message?.comments) || 0;
      case 'flipkart':
        return formatNumber(message?.dislikes) || 0;
      case 'amazon':
        return message.verified === 'Verified Purchase' && (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='27'
            height='27'
            viewBox='0 0 27 27'
            fill='#209E0B'
          >
            <path
              d='M0.920898 13.2318C4.6835 17.3029 8.33166 20.9679 11.8385 25.6033C15.6512 18.0201 19.5535 10.4105 25.9924 2.16971L24.2574 1.375C18.8205 7.14098 14.5965 12.599 10.9262 19.0853C8.37385 16.7861 4.24897 13.5324 1.73037 11.8607L0.920898 13.2318Z'
              fill='#209E0B'
            />
          </svg>);
      default:
        return 0
    }
  }
  return (
    <Wrapper>

      <RowData className='header' style={{ ...graphStyles }}>
        {columns.map((column, i) => {
          if (filterCol.includes(i + 1)) {
            return (
              <div
                className='filterColumn'
                key={i}
                onClick={() => handleFilter(i)}
              >
                {column}&nbsp;
                {sort_icon}
              </div>
            );
          }
          return <div key={i} style={{ paddingLeft: '15px' }}>{column}</div>;
        })}
      </RowData>
      <RowWrapper>
        {currentTableData.map((message, index) => (
          <RowData key={index} style={{ ...graphStyles }}>
            <div className='review'>
              <div>
                Post on{' '}
                {formatDate(channel === 'twitter' ? message.date : message.date
                  || message['Reviewed on'])}
              </div>
              <div>
                <CollapsibleText text={
                  channel === 'twitter' ? message.text : message.posts
                    || message.review_meta || message?.text
                }
                />
              </div>
              <CollapsibleText text={message.review_text && message.review_text} />
            </div>
            {columns.includes('Profile') && <div className='profile'>
              <span>{message.user_name || message.name}</span>
              <span>{message['@user_name']}</span>
            </div>}
            <div className='helpful'>
              {channel === 'twitter' ? formatNumber(message?.followers) : channel === 'amazon' ?
                formatNumber(message.helpful) || 0
                : formatNumber(message.likes) || 0}
            </div>
            <div className='verified'>
              {getDetails(channel, message)}
            </div>
            {
              channel === 'facebook' ?

                <div>
                  {message?.shares || 0}
                </div>
                : ''
            }
          </RowData>
        ))}
      </RowWrapper>
      {
        !download && <Pagination
          currentPage={currentPage}
          totalCount={reviewsData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
          reviewsData={reviewsData}
        />
      }

    </Wrapper>
  );
};

export default CommonMessagesTab;
