import styled from "styled-components";


export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  //height:100vw;
  flex-direction: column;
  //background:red;
`;

export const BoxWrapper = styled.div`
  width: 43px;
  display: flex;
  margin-top: 15px;
  align-items: center;
  height: 42px;
  flex-shrink: 0;
`;

export const HeadingSpan = styled.span`
  display: flex;
  margin: 30px 0px 0px 30px;
  color: #636363;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0px 0px 30px;
  width: 95%;
  height: auto;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  p {
    display: flex;
    width: 411px;
    flex-direction: column;
    flex-shrink: 0;
    margin: 100px 0px 0px 20px;

    color: #636363;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
`;

export const PieDiv = styled.div`
  display: flex;
  margin: 0px 0px 0px 20px;

  div {
    display: flex;
    margin: 0px 0px 0px 30px;
  }
`;

export const HeadSpan3 = styled.span`
  display: flex;
  margin: 35px 0px 0px 30px;
  color: #636363;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const DivWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  span {
    margin-right: 15px;
  }

  p {
    margin-left: 40px;
  }
`;

export const HeadingSpan1 = styled(HeadingSpan)`
  margin-top: 20px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin: 40px 0px 0px 25px;
`;

export const ContentWrapper5 = styled.div`
  display: flex;
  margin: 40px 0p 0px 20px;
  flex-direction: column;
  width: 47%;
  height: 350px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  &>div: first-child {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  p {
    color: #636363;

    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;

    margin-left: 20px;
  }
`;

export const ReviewsWrapper = styled.div`
  width: 95%;
  height: auto;
  margin: 30px 0px 0px 30px;

  border-radius: 15px;
  border: 1px solid #d9d9d9;

  &>div: first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const UserSpan = styled.span`
  cursor: pointer;
  height: 18px;
  padding: 5px 20px;
  border: 1px solid #e4e4e4;
  border-radius: ${(props) => (props.selected ? '6px 0px 0px 6px' : '1px')};
  background-color: ${(props) => (props.selected ? '#353CF7' : 'transparent')};
  color: ${(props) => (props.selected ? '#FFF' : 'black')};

  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin: 30px 0px 0px 0px;
`;

export const ReviewContainer = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  width: 90%;
  height: auto;
  border-radius: 15px;
  padding: 20px;
  margin: 10px 0px 0px 25px;

  background: rgba(92, 227, 144, 0.2);

  display: grid;
  grid-template-columns: 92% 8%;
  align-items: center;
  gap: 20px;

  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin: 0px 0px 10px 40px;
  }
`;

export const Container1 = styled.div`
  display: flex;
  flex-direction: column;

  &>span: first-child {
    color: #000;

    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  }

  &>span: last-child {
    width: 100%;
    text-align: center;
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    margin-left: -15px;
  }
`;

export const ReviewContainer1 = styled(ReviewContainer)`
  background: rgba(249, 98, 89, 0.18);
`;

export const ReviewContainer2 = styled(ReviewContainer)`
  background: rgba(254, 222, 141, 0.37);
  margin-bottom: 20px;
`;

/*  const RedCircle = styled(Circle)`
  background: #fc6758;
`;

export const NeutralCricle = styled(Circle)`
  background: #f5cc61;
`; */

export const TopPostWrapper = styled.div`
  & > div {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 1em;
    border-radius: 15px;
    padding: 20px;

    .reviewText {
      flex: 1;
      border-right: 1px solid #d9d9d9;
      padding: 0 10px;
    }
    .reviewCount {
      width: 10%;
      text-align: center;
    }
  }

  .positiveReview {
    background: rgba(92, 227, 144, 0.2);
  }

  .negativeReview {
    background: rgba(249, 98, 89, 0.18);
  }

  .neutralReview {
    background: rgba(254, 222, 141, 0.37);
  }

  .peopleLiked {
    font-size: 10px;
  }
`;

export const Circle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;

  border: 2px solid #fff;
`;

export const PositiveCircle = styled(Circle)`
  background: #5ce390;
`;

export const NeutralCircle = styled(Circle)`
  background: #f5cc61;
`;

export const NegativeCircle = styled(Circle)`
  background: #fc6758;
`;

export const TopRepostWrapper = styled.div`
  margin-top: 30px;
  margin-left: 25px;
  width: 92%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  align-items: center;
`;

export const PostCountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  padding: 0 18px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3em;
  }
`;

export const Footer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  text-align: right;
  margin-top: 30px;
`;