import styled from 'styled-components';

export const Wrapper = styled.div`
  box-sizing: border-box;
  font-family: Open Sans;
  width: 100%;
  padding: 30px;
  height: 100%;
  overflow-y: scroll;
  text-align: left;
`;

export const TopicDiv = styled.span`
  height: 25px;
  display: flex;
  position: relative;
  flex-direction: row;
  text-align: left;
  color: #636363;

  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
`;

export const Desc = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
`;

export const NewTopic = styled.div`
  box-sizing: border-box;
  margin: 30px 0;
  width: 95%;
  padding: 10px 25px 30px 25px;
  background: #f2f3ff;
  border-radius: 15px;
`;

export const NewTopicSpan = styled.h3`
  color: #353cf7;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
`;

export const NewTopicDesc = styled.p`
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  margin: 22px 0 30px 0;
`;

export const NewButton = styled.button`
  background: #353cf7;
  border-radius: 100px;
  border: none;
  color: #fff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  padding: 8px 26px;
`;

export const ActiveTopic = styled.div`
  .topicWrapper {
    display: flex;
    gap:10px;
    cursor:pointer;
    flex-wrap: wrap;
  }
`;

export const Heading = styled.p`
  color: #2a2929;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
`;

export const ActiveTopicDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #353cf75e;
  width: 131px;
  height: 84px;
  border-radius: 10px;

  font-family: Montserrat;
  font-weight: 500;
  font-size: 18px;
  padding: 15px 20px;
`;
