import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './alertMessageSlice';

import ExecutivePortalTenantManagement from '../services/executivePortalTenantManagement.service';

export const getAllUserOf_A_Tenant = createAsyncThunk(
  'getAllUserOf_A_Tenant',
  async (tenant_id, thunkAPI) => {
    try {
      const data = await ExecutivePortalTenantManagement.getAllUserOf_A_Tenant(
        tenant_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createUser = createAsyncThunk(
  'createUser',
  async (body, thunkAPI) => {
    try {
      const updatedArray = body.newMembers.map((element) => {
        const user_name = element.userName;
        const first_name = element.firstName;
        const last_name = element.lastName;
        const password = element.password;
        const email_id = element.email;
        const role_id = element.role === 'Brand User' ? 200 : 100;
        const tenant_id = body.tenant_id;

        return {
          user_name,
          first_name,
          last_name,
          password,
          email_id,
          role_id,
          tenant_id,
        };
      });
      await ExecutivePortalTenantManagement.createUser(updatedArray);
      const data = await ExecutivePortalTenantManagement.getAllUserOf_A_Tenant(
        body.tenant_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteUser = createAsyncThunk(
  'deleteUser',
  async (body, thunkAPI) => {
    try {
      await ExecutivePortalTenantManagement.deleteUser(body);
      const data = await ExecutivePortalTenantManagement.getAllUserOf_A_Tenant(
        body.tenant_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const setCurrentTenant = createAsyncThunk(
  'setCurrentTenant',
  (selectedTenant) => {
    return selectedTenant;
  }
);

export const tenanTBasicDetails = createAsyncThunk(
  'tenanTBasicDetails',
  async (tenant_id, thunkAPI) => {
    try {
      const data = await ExecutivePortalTenantManagement.tenanTBasicDetails(
        tenant_id
      );
      const dataSet2 =
        await ExecutivePortalTenantManagement.tenanTBasicDetails_extras(
          tenant_id
        );
      return { ...data, ...dataSet2 };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateTenantBasicDetails = createAsyncThunk(
  'updateTenantBasicDetails',
  async ({ changedData, tenant_id }, thunkAPI) => {
    try {
      const data =
        await ExecutivePortalTenantManagement.updateTenanTBasicDetails(
          changedData,
          tenant_id
        );
      thunkAPI.dispatch(setMessage('Tenant setting details updated'));
      return { ...data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPersonalDetailsOfTenantTeamMember = createAsyncThunk(
  'getPersonalDetailsOfTenantTeamMember',
  async (body, thunkAPI) => {
    try {
      const data =
        await ExecutivePortalTenantManagement.personalDetailsOfTenantUser(
          body.tenant_id,
          body.user_id
        );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatePersonalDetailsOfTenantTeamMember = createAsyncThunk(
  'updatePersonalDetailsOfTenantTeamMember',
  async ({ user_id, tenant_id, ...body }, thunkAPI) => {
    try {
      const data =
        await ExecutivePortalTenantManagement.updatePersonalDetailTenantUser(
          tenant_id,
          user_id,
          body
        );
      thunkAPI.dispatch(setMessage('Successfully updated details'));
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  userTeamMemberListData: null,
  selectedTenantData: null,
  tenantBasicData: null,
  getPersonalDetailsOfTenantTeamMemberData: [],
};

const ExecutivePortalTenantManagementSlice = createSlice({
  name: 'executivePortalTenantManagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserOf_A_Tenant.fulfilled, (state, action) => {
        state.userTeamMemberListData = action.payload.data;
      })
      .addCase(getAllUserOf_A_Tenant.rejected, (state, action) => {
        state.userTeamMemberListData = null;
        console.log('rejected');
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.userTeamMemberListData = action.payload.data;
      })
      .addCase(createUser.rejected, (state, action) => {
        console.log('rejected');
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.userTeamMemberListData = action.payload.data;
        console.log('User deleted successfully');
      })
      .addCase(deleteUser.rejected, (state, action) => {
        console.log('User deleted failed');
      })
      .addCase(setCurrentTenant.fulfilled, (state, action) => {
        state.selectedTenantData = action.payload;
      })
      .addCase(tenanTBasicDetails.fulfilled, (state, action) => {
        state.tenantBasicData = action.payload;
        console.log('Tenant Basic Details successfully');
      })
      .addCase(tenanTBasicDetails.rejected, (state, action) => {
        console.log('Tenant Basic Details rejected');
      })
      .addCase(updateTenantBasicDetails.fulfilled, (state, action) => {
        state.tenantBasicData = { ...state.tenantBasicData, ...action.payload };
        console.log('Tenant Basic Details update successfully');
      })
      .addCase(updateTenantBasicDetails.rejected, (state, action) => {
        console.log('Tenant Basic Details update rejected');
      })
      .addCase(
        getPersonalDetailsOfTenantTeamMember.fulfilled,
        (state, action) => {
          state.getPersonalDetailsOfTenantTeamMemberData.push(
            action.payload.data
          );
        }
      )
      .addCase(
        getPersonalDetailsOfTenantTeamMember.rejected,
        (state, action) => {
          console.log('rejected');
        }
      )
      .addCase(
        updatePersonalDetailsOfTenantTeamMember.fulfilled,
        (state, action) => {
          const usernameToReplace = action.payload.data?.user_name;

          state.getPersonalDetailsOfTenantTeamMemberData =
            state.getPersonalDetailsOfTenantTeamMemberData.map((user) =>
              user.user_name === usernameToReplace ? action.payload.data : user
            );
          state.userTeamMemberListData = state.userTeamMemberListData.map(
            (user) => {
              if (user.user_name === usernameToReplace) {
                user.first_name = action.payload.data?.first_name;
                user.email_id = action.payload.data?.email_id;
                return user;
              } else {
                return user;
              }
            }
          );
        }
      )
      .addCase(
        updatePersonalDetailsOfTenantTeamMember.rejected,
        (state, action) => {
          console.log('rejected');
        }
      );
  },
});

export default ExecutivePortalTenantManagementSlice.reducer;
