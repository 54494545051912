import React, { useState } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import Loader from '../../../assests/animation/Loader2.json'
import OverallReview from '../../../components/OverallReview/OverallReview';
import { InstagramLogo, X_icon } from '../../../Common/SvgIcon/CommonSvgIcons';
import GraphHOC from '../../../Common/GraphHOC/GraphHOC';
import WordCloudComponent from '../../../components/WordCloud/WordCloud';
// import TrendsInUserActivity from '../../../Common/TendsInUserActivity/TrendsInUserActivity';
import TrendsInUserActivity from '../../../Common/TendsInUserActivity/TrendsInUser_activity';

import BarGraph from '../../../Common/BarGraph/BarGraph';
import RatingDonutChart from '../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../Common/FunnelGraph/FunnelGraph';
import VerticalBarGraph from '../../../Common/BarGraph/VarticalBargraph';
import { Bar } from 'recharts';

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const Container = styled.div`
  display: flex;
  gap: 30px;
  height: 380px;
`;

const ChildContainer = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  /* justify-content: center; */
  /* align-items: center; */
  box-sizing: border-box;
  padding: 5px;

  span {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 0px 10px;
  }

  div {
    margin: 10px 0px 0px 0px;
  }
`;

const Overview = ({
  
  overview_tab,
  startDate = '',
  endDate = '',
  filteredStartDate,
  filteredEndDate,
  filterOption,
  filteredData,
  resetFlag,
  view,
  channel,
  hideDropdown
}) => {
  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

  const genderDemographicsData = [
    {
      name: 'Male',
      value: resetFlag === true && filteredData ? filteredData?.gender_demographics?.male_percentage : overview_tab?.gender_demographics?.male_percentage,
      fill: '#6640B8',
    },
    {
      name: 'Female',
      value: resetFlag ===  true && filteredData ? filteredData?.gender_demographics?.female_percentage : overview_tab?.gender_demographics?.female_percentage,
      fill: '#EAAABD',
    },
    {
      name: 'Unknown',
      value: resetFlag === true && filteredData ? filteredData?.gender_demographics?.unknown_percentage : overview_tab?.gender_demographics?.unknown_percentage,
      fill: '#E4E4E4',
    },
  ];

  const totalNoOfPosts = resetFlag === true && filteredData ? filteredData?.total_count :
  overview_tab?.total_count

  const renderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={genderDemographicsData}
          type={'%'}
          view={view}
        />
      ) : (
        <BarGraph
          data={genderDemographicsData}
          type={'%'}
         /*  borderRadius={'0px 12px 12px 0px'} */
          barHeight={'32px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={genderDemographicsData}
        width={450}
        type='percentage'
      />
    ),
    'Donut Chart': (
      <DonutChart data={genderDemographicsData} type='percentage' />
    ),
    'Funnel Chart': <FunnelGraph data={genderDemographicsData} type={'%'} />,
  };

  let genderDemographicsGraph;

  if (filterOption) {
    if (filterOption === 'Bar Graph') {
      genderDemographicsGraph = renderScreen['Bar Graph'];
    } else if (filterOption === 'Pie Chart') {
      genderDemographicsGraph = renderScreen['Pie Chart'];
    } else if (filterOption === 'Donut Chart') {
      genderDemographicsGraph = renderScreen['Donut Chart'];
    } else {
      genderDemographicsGraph = renderScreen['Funnel Chart'];
    }
  }


  return (
    <>
    {
      resetFlag === true && filteredData === undefined ? (
        <div style={{ marginTop: '-80px' }}>
        <Lottie
          data-testid='loader'
          animationData={Loader}
          loop={true}
          width='100px'
          height='100px'
        />
      </div>
      ) : (
        <Wrapper>
        <Container>
          <ChildContainer>
            <OverallReview
              icon={X_icon}
              message= {`${totalNoOfPosts} Relevant posts were observed From ${resetFlag ? filteredStartDate : startDate} to ${resetFlag ? filteredEndDate : endDate}`}
            />
          </ChildContainer>
          {/* {filterOption ? (
            <FilterContainer>
            <span>Gender Demographics</span>
            <div>{genderDemographicsGraph}</div>
          </FilterContainer>
          ) : ( */}
            <ChildContainer>
              <GraphHOC
                title={'Gender Demographics'}
                options={options}
                renderScreen={renderScreen}
                startDate={resetFlag ? filteredStartDate : startDate}
                endDate={resetFlag ? filteredEndDate : endDate}
                option={filterOption}
                hideDropdown={hideDropdown}
              />
            </ChildContainer>
          {/* )} */}
        </Container>
        <ChildContainer>
          <TrendsInUserActivity
            data1={{
              trends_in_user_activity:resetFlag === true && filteredData ? filteredData?.trends_in_user_activity : overview_tab?.trends_in_user_activity,
            }}
            ylabel='Reviews'
            title='Trends In User Activity'
            hideDropdown={hideDropdown}
            startDate={resetFlag ? filteredStartDate : startDate}
            endDate={resetFlag ? filteredEndDate : endDate}
          />
        </ChildContainer>
      </Wrapper>
      )
    }
    </>
  );
 
};

export default Overview;
