import { margin } from '@mui/system';
import * as React from 'react';
import { PieChart, Pie, Tooltip, Legend } from 'recharts';
import styled from 'styled-components';

const Wrapper = styled.div`
//  margin-left: 6%;
`;

const RatingDonutChart = ({ data, width = 450, type }) => {
  // Sample data
  /* const data = [
    { name: 'Geeksforgeeks', students: 400 },
    { name: 'Technical scripter', students: 700 },
    { name: 'Geek-i-knack', students: 200 },
    { name: 'Geek-o-mania', students: 1000 },
  ]; */
  // const finalData = data.filter(row => row.value !== 0);
  let finalData = data
  .map(row => ({ ...row, value: parseFloat(row.value) }))
  .filter(row => row.value > 0)
  const sortedData = finalData.sort((a, b) => b.value - a.value);


  const renderCustomLabel = (type) => ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    if (percent <= 0.001) {
      return null;
    }
    const radius = innerRadius + (outerRadius - innerRadius) * 1.25;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize="14px"
      >
        {`${sortedData[index].name}- ${
          typeof sortedData[index]?.value === 'number' && sortedData[index]?.value
        }${type === 'percentage' ? '%' : ''
          }`}
      </text>
    );
  };


  const tooltipFormatter = (value, name, props) => {

   

    // Assuming 'name' is your star rating (like '5star') and 'value' is the corresponding value
    return ` ${ typeof value === 'number' && value !== 0 && value}`;
  };


  return (
    <Wrapper>
      {/* <PieChart width={width} height={280} viewBox={`93 0 ${width} 280`}
      > */}

      <PieChart  width={width} height={280}>
        <Pie
          data={sortedData}
          dataKey="value"
          outerRadius={100}
          // Ensure viewBox covers the necessary area
          
          /* fill='#6F62FE' */
          /*  label={({ name, value }) => {
          if (value !== 0) {
            return `${name}: ${value}%`;
            return <text x={100} y={50} dy={100} textAnchor="middle" fill="black">
              {`${name}: ${value}%`}
            </text>
          } else {
            return null; // Return null to hide the label
          }
        }} */
          //  label={renderCustomLabel(type)}
          fill={(entry) => entry.fill}
        />
        <Legend layout='vertical' verticalAlign='middle' align='right' />
        <Tooltip formatter={tooltipFormatter} />
      </PieChart>
    </Wrapper>
  );
};

export default RatingDonutChart;
