import tenantAxiosInstance from '../utils/tenantAxiosInstance';

const API_URL = process.env.REACT_APP_BACKEND_URL; 

const getHomepageDetails = (tenant_id) => {
  return tenantAxiosInstance
    .get(API_URL + `${tenant_id}/homepage/`)
    .then((response) => {
      return response.data;
    });
};

const personalDetails = (tenant_id, user_id) => {
  return tenantAxiosInstance
    .get(API_URL + `${tenant_id}/user_personal_settings/${user_id}`)
    .then((response) => {
      return response.data;
    });
};

const updatePersonalDetail = (tenant_id, user_id, body) => {
  return tenantAxiosInstance
    .put(API_URL + `${tenant_id}/personal_settings/${user_id}`, body)
    .then((response) => {
      return response.data;
    });
};

const getCompanyBasicDetails = (tenant_id) => {
  return tenantAxiosInstance
    .get(API_URL + `${tenant_id}/basic_details`)
    .then((response) => {
      return response.data;
    });
};

const getTeamMembers = (tenant_id) => {
  return tenantAxiosInstance
    .get(API_URL + `${tenant_id}/team_list`)
    .then((response) => {
      return response.data;
    });
};

const deleteTeamMembers = (tenant_id, user_id) => {
  return tenantAxiosInstance
    .delete(API_URL + `${tenant_id}/delete_user/${user_id}`)
    .then((response) => {
      return response.data;
    });
};

const addTeamMembers = (body, tenant_id) => {
  
  return tenantAxiosInstance
    .post(API_URL + `${tenant_id}/create_user/`, body)
    .then((response) => {
      return response.data;
    });
};

const getPlansBillings = () => {
  return tenantAxiosInstance
    .get(API_URL + 'plans_billings/')
    .then((response) => {
      return response.data;
    });
};

const TenantSettingService = {
  getHomepageDetails,
  personalDetails,
  updatePersonalDetail,
  getCompanyBasicDetails,
  getTeamMembers,
  deleteTeamMembers,
  addTeamMembers,
  getPlansBillings,
};

export default TenantSettingService;
