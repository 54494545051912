import React, { useEffect, useState } from 'react';
import MessagesTab from '../../../../../components/MessageTab/MessageTab';
import { useDispatch, useSelector } from 'react-redux';
import {
  reviewsForChatter,
  reviewsForChatterPreview,
  reviewsFilterChatter,
  reviewsFilterChatterPreview,
  chatterReviewsForDownload,
} from '../../../../../store/redux/slices/chatterSlice';
import styled from 'styled-components';
import Button from '../../../../../Common/Button/Button';
import { AmazonIconSmall, FlipkartIconSmall } from '../../../../../Common/SvgIcon/CommonSvgIcons';
import { flexbox } from '@mui/system';

const Wrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 30px;
  margin: 30px;
  font-size: 15px;
  font-weight: 600;

  & > :first-child {
    box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
    padding: 8px 7px 5px 7px;
    border-radius: 10px;

    & svg {
      width: 30px;
      height: 30px;
    }
  }

  & input {
    box-shadow: 0px 0px 8px 0px #a2a2a23d;
    border: 1px solid #d9d9d9;
    margin-top: 5px;
    margin-left: -3px;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    color: #636363;

    padding: 5px 20px;
    border-radius: 5px;
  }
`;

function CommentsTab({ email_id,download,job_id,channelName, startDate, endDate, hideDropdown }) {
  const [firstDate, setFirstDate] = useState(startDate);
  const [lastDate, setLastDate] = useState(endDate);
  const [filterData, setFilteredData] = useState(null)
  const dispatch = useDispatch();
  const { reviewsForPreviewData } =
    useSelector((state) => state.chatterTracker) || {};

  const { reviewsForChatterData } =
    useSelector((state) => state.chatterTracker) || {};

  const { reviewsData } = useSelector((state) => state.chatterTracker) || {};

  useEffect(() => {
    if(download){
      dispatch(chatterReviewsForDownload({
        job_id,
        email_id
      }));
    }else if (typeof job_id === 'number' && reviewsForChatterData === null) {
      dispatch(reviewsForChatter(job_id));
    } else if (reviewsForChatterData === null) {
      dispatch(reviewsForChatterPreview());
    }
  }, [job_id]);
  
  const dateHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'firstDate') {
      setFirstDate(value);
    } else if (name === 'lastDate') {
      setLastDate(value);
    }
  };

  const handleSave = () => {
    const dates = {
      start_date : firstDate, end_date : lastDate
    }
    dispatch(
       reviewsFilterChatter({ dates, job_id })
    )
    .then((res) => {
      setFilteredData(res.payload.data.reviews);
    })
    .catch((error) => {
      console.error('Error:', error); // Handling any errors
    });
  }
  return (
    <div style={{display: flexbox, flexDirection: 'column',width: '96%',marginLeft: 'auto', marginRight: 'auto',marginTop: '40px'}}>
    {
      channelName && !hideDropdown &&
      <Wrapper>
          <div>{channelName === 'flipkart' ? FlipkartIconSmall : AmazonIconSmall}</div>
          <div>
            <div>First Date:</div>
            <input
              type='date'
              name='firstDate'
              value={firstDate}
              onChange={dateHandler}
              min={startDate}
              max={endDate}
              />
          </div>
          <div>
            <div>Last Date:</div>
            <input
              type='date'
              name='lastDate'
              value={lastDate}
              onChange={dateHandler}
              min={startDate}
              max={endDate}
            />
          </div>
          <Button
            value={'Save'} 
            onClick={handleSave} 
            style={{
              width: '100px', 
              borderRadius: '10px', 
              height: '35px', 
              fontSize: '18px'
            }}
          />
        </Wrapper>
    }
    <MessagesTab
      channel={channelName}
      reviews={
        filterData ? filterData : reviewsForChatterData?.reviews || []
      }
      columns={channelName === 'flipkart' ? ['Comments', 'Profile', 'Likes', 'Dislikes'] : ['Reviews', 'Profile', 'Helpful', 'Verified']}
    />
    </div>
  );
}

export default CommentsTab;