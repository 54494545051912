import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';

import { AddIcon, SubIcon } from '../../../../Common/SvgIcon/CommonSvgIcons';
import React, { useState, useRef, useEffect } from 'react';

const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  & span {
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  border-radius: 20px;
  background: #fff;

  width: 368.3px;
  height: 44.467px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 20px;
  height: 20px;
  border: 1px solid rgba(152, 152, 152, 0.24);
  margin: 0 10px;
  border-radius: 50%;
  color: #fff;
  background: #353cf7;

  ${({ active }) => active === 'false' && 'background: #fff'}
`;

const KeyWordType = styled.div`
  position: relative;
  height: 100%;
  width: 24%;

  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 27.067px;
  background: #000;
  color: #fff;
  padding: 0 22px;
  height: 100%;
  font-size: 15px;
  font-family: Open Sans;
  font-weight: 600;

  & > svg {
    position: absolute;
    right: 0px;
  }
`;
const TextArea = styled.input`
  height: 100%;
  outline: none;
  border: none;
  width: 50%;
  padding: 0 15px;

  color: #636363;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;

  border-radius: 20px;
  flex: 1;
`;

const KeyWordTypeList = styled.ul`
  position: absolute;

  top: 90%;
  left: 0%;

  background: #fff;
  padding: 10px 0;

  width: 200px;

  box-shadow: 0px 0px 8px rgba(85, 85, 85, 0.25);
  border-radius: 15px;

  font-size: 20px;
  font-weight: 500;

  z-index: 100;

  & > li {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 15px;

    color: #707070;

    &:hover {
      background-color: #f2f2f2;
    }
  }
`;

const keywordType = [
  'Word ',
  'Phrase',
  'Hashtags ',
  'Mentions ',
  'From User ',
  'To User ',
];

const Input = ({
  inputData: {
    index,
    inputChangeHandler,
    removeNewKeyword,
    addNewKeyword,
    keyword,
    changeKeywordType,
  },
}) => {
  const [optionTab, setOptionTab] = useState(false);

  const inputRef = useRef(null);
  const selectedOption = keyword.keywordType;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setOptionTab(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <Card>
      <InputWrapper>
        <KeyWordType
          onClick={() => setOptionTab((prev) => !prev)}
          ref={inputRef}
        >
          {selectedOption} <KeyboardArrowDownIcon />
          {optionTab && (
            <KeyWordTypeList>
              {keywordType.map((op) => (
                <li key={op} onClick={() => changeKeywordType(op, index)}>
                  <StyledCheckIcon
                    active={(selectedOption.trim() === op.trim()).toString()}
                  />
                  {op}
                </li>
              ))}
            </KeyWordTypeList>
          )}
        </KeyWordType>
        <TextArea
          onChange={(e) => inputChangeHandler(index, e.target.value)}
          placeholder='Enter keyword... '
          value={keyword.value}
        />
      </InputWrapper>
      <span onClick={addNewKeyword}>{AddIcon}</span>
      <span onClick={() => removeNewKeyword(index)}>{SubIcon}</span>
    </Card>
  );
};

export default Input;
