import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';
import { logoutTenant } from '../../store/redux/slices/tenantAuthSlice';
import {
  HomeIcon,
  ListenerIcon,
  SettingIcon,
  NotificationIcon,
  LogoutIcon,
  UserIcon,
  ChatterTrackerIcon,
  sentinelHub_icon,
  AlertIcon,
  CompetitiveInteligence,
} from '../../Common/SvgIcon/CommonSvgIcons';
import UserProfileIcon from '../../Common/Avatar/Avatar.jsx';
import {
  LogoutPopUP,
  Triangle,
} from '../../Common/CommonStyledElement/CommonStyledElement';

const Wrapper = styled.div`
  height: 60px;
  background: #353cf7;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  & > * {
    display: flex;
    gap: 12px;
    padding: 10px;
  }

  // Style for BA LOGO
  & > :nth-child(1) {
    margin-left: 15px;
    img {
      width: 50px;
      cursor: pointer;
    }
  }

  // Style for Tabs
  & > :nth-child(2) {
    & > * {
      width: 18px;
      height: 18px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 50%;
      cursor: pointer;

      & svg {
        width: 17px;
      }
    }
  }
  & > :nth-child(3) {
    justify-content: end;
    align-items: center;
    margin-right: 20px;

    // Alert Icon
    & > :nth-child(1) {
      width: 14px;
      height: 14px;
      padding: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      cursor: pointer;
      border-radius: 50%;
      position: relative;

      & > span {
        position: absolute;
        top: -4px;
        right: -4px;
        background: #fc6758;
        width: 18px;
        height: 18px;
        font-size: 12px;
        border-radius: 50%;
        color: #fff;
      }
    }

    // Notification Icon
    & > :nth-child(2) {
      width: 14px;
      height: 14px;
      padding: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      cursor: pointer;
      border-radius: 50%;
      position: relative;

      & > span {
        position: absolute;
        top: -4px;
        right: -4px;
        background: #fc6758;
        width: 18px;
        height: 18px;
        font-size: 12px;
        border-radius: 50%;
        color: #fff;
      }
    }
  }

  // Style for active tab navigation
  .activeTab {
    background: #fff;
    width: auto;
    border-radius: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 600;
    transition: all 50ms linear;
    & svg {
      width: 17px;
    }
  }

  .alertActiveTab {
    
  }
`;

function Header2() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(1);
  const [logoutPopupVisible, setLogoutPopupVisible] = useState(false);
  const popUpRef = useRef(null);
  const dispatch = useDispatch();

  let url = location.pathname.split('/')[1]; //to get the url

  //to hide logout popup on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        setLogoutPopupVisible(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (url === 'homepage') {
      setActiveTab(1);
    } else if (url === 'topic') {
      setActiveTab(2);
    } else if (url === 'chatter') {
      setActiveTab(3);
    } else if (url === 'setting') {
      setActiveTab(4);
    } else if (url === 'sentinelHub') {
      setActiveTab(5);
    } else if (url === 'alert'){
      setActiveTab(6);
    }
  }, [url]);

  // If there is no token then logout user
  const user = JSON.parse(localStorage.getItem('brandUser'))?.user_details;
  // useEffect(() => {
  //   if (!user || user === null) {
  //     navigate('/login');
  //   }
  // }, [url]);

  //Show PopUp on user icon click
  const handleUserIconClick = () => {
    setLogoutPopupVisible(!logoutPopupVisible);
  };

  const tabList = [
    {
      id: 1,
      link: '/homepage',
      title: 'Home',
      icon: HomeIcon,
      testId: 'item-0',
    },
    {
      id: 2,
      link: '/topic/home',
      title: 'Topic Listening',
      icon: ListenerIcon,
      testId: 'item-1',
    },
    {
      id: 3,
      link: '/chatter/home',
      title: 'Chatter Tracker',
      icon: ChatterTrackerIcon,
      testId: 'item-2',
    },
    {
      id: 6,
      link: '/competitiveIntelligence/home',
      title: 'Competitive Intelligence',
      icon: CompetitiveInteligence,
      testId: 'item-5',
    },
    {
      id: 5,
      link: '/sentinelHub',
      title: 'Sentinel Hub',
      icon: sentinelHub_icon,
      testId: 'item-4',
    },
    {
      id: 4,
      link: '/setting/personal',
      title: 'Settings',
      icon: SettingIcon,
      testId: 'item-3',
    },
  ];

  const selectTabHandler = (tab = 1, link = '/homepage') => {
    setActiveTab(tab);
    navigate(link);
  };

  const navigateToSetting = () => {
    navigate('/setting/personal');
    setLogoutPopupVisible(!logoutPopupVisible);
  };

  const logoutHandler = () => {
    setLogoutPopupVisible(!logoutPopupVisible);
    dispatch(logoutTenant());
    navigate('/login');
    window.location.reload();
  };

  const handleAlertIconClick = () => {
    setActiveTab(6);
    navigate('/alert')
  }

  return (
    <Wrapper ref={popUpRef}>
      <div onClick={() => navigate('/login')}>
        <img
          src={`${process.env.PUBLIC_URL}/images/Brand Astra_BA.png`}
          alt='logoImg'
        />
      </div>
      <div className='tabContainer'>
        {tabList.map(({ id, link, title, icon, testId }) => (
          <Tooltip title={activeTab === id ? '' : title}>
            <div
              className={activeTab === id ? 'activeTab' : ''}
              key={id}
              onClick={() => selectTabHandler(id, link)}
              data-testid={testId}
            >
              {icon} {activeTab === id && title}
            </div>
          </Tooltip>
        ))}
      </div>
      <div>
        <div className={activeTab === 6 ? 'alertActiveTab' : ''} onClick={handleAlertIconClick}>
          {AlertIcon}
        </div>
        <div>
          {NotificationIcon}
          <span>1</span>
        </div>
        <div onClick={handleUserIconClick} data-testid={'logout'}>
          <UserProfileIcon
            picture={user?.profile_pic || ''}
            userName={user?.first_name || ''}
            bgColor={user?.role_id === 100 ? '#00D5D0' : '#00D5D0'}
          />
        </div>
        {logoutPopupVisible && (
          <LogoutPopUP>
            <div className='userPic'>
              <UserProfileIcon
                picture={user?.profile_pic || ''}
                userName={user?.first_name || ''}
                bgColor={user?.role_id === 100 ? '#00D5D0' : '#00D5D0'}
              />
            </div>
            <div className='userName'>{user?.user_name}</div>
            <div>{user?.email_id}</div>
            <div className='line'></div>
            <div className='myProfile_logout' onClick={navigateToSetting}>
              {UserIcon}
              My Profile
            </div>
            <div className='myProfile_logout' onClick={logoutHandler}>
              {LogoutIcon}
              Logout
            </div>
          </LogoutPopUP>
        )}
        {logoutPopupVisible && <Triangle />}
      </div>
    </Wrapper>
  );
}

export default Header2;
