import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PopUp from '../../Common/PopUp/PopUp';
import TitleWithInfo from '../../Common/TitleWithInfo/TitleWithInfo';

const Wrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;

  gap: 50px;
  height: 85%;
  user-select: none;
`;

const GraphWrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: end;
  // border-left: 2px solid rgba(0, 0, 0, 0.38);
  // border-bottom: 2px solid rgba(0, 0, 0, 0.38);
  padding: 20px;
  box-sizing: border-box;
`;

const GraphAxis = styled.div`
  height: 100%;
  width: 90%;
  position: relative;
  box-sizing: border-box;

  color: #636363;
  font-family: Open Sans;
  font-weight: 600;

  & > .clusterPoints {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    box-sizing: border-box;
    transition: all 0.2s linear;

    & > div {
      display: none;
    }

    &:hover {
      width: 18px;
      height: 18px;
      cursor: pointer;
      box-shadow: 0 0 10 10px #222;

      & > div {
        display: unset;
      }
    }
  }

  & > .clusterCircles {
    position: absolute;
    border-radius: 50%;
    box-sizing: border-box;
    transition: all 0.2s linear;

    & > div {
      display: none;
    }

    &:hover {
      width: 18px;
      height: 18px;
      cursor: pointer;
      box-shadow: 0 0 10 10px #222;

      & > div {
        display: unset;
      }
    }
  }

  & > .yAxis {
    left: -60px;
    // left: -8%;
    width: 30px;
    text-align: right;
    position: absolute;
  }

  & > .xAxis {
    bottom: -50px;
    // bottom: -6%;
    width: 30px;
    text-align: left;
    position: absolute;
  }
`;

const ReviewWrapper = styled.div`
  position: relative;
  // padding: 20px;
`;
const Review = styled.div`
  box-sizing: border-box;
  max-width: 220px;
  // height: 50px;
  position: absolute;
  bottom: 20px;
  left: -30px;
  border-radius: 8px;
  background: #e2e2fe;
  z-index: 4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  padding: 10px;

  color: rgba(0, 0, 0, 0.81);
  font-size: 12px;
  font-weight: 400;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Pointer = styled.div`
  width: 0px;
  height: 0px;
  position: absolute;
  bottom: 2px;
  left: -2px;
  z-index: 5;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 18px solid #e2e2fe;
`;

const Pointer2 = styled.div`
  width: 0px;
  height: 0px;
  position: absolute;
  bottom: 2px;
  left: 12px;
  z-index: 5;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 18px solid #e2e2fe;
`;

const GraphLabelWrapper = styled.div``;

const GraphLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  color: #636363;
  font-size: 16px;
  font-weight: 600;

  &:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    // background: red;
    background: ${(props) => props.backgroundColor};
  }
`;

const TextWrapper = styled.div`
  text-align: left;
  & > * {
    float: left;
    clear: both;
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  span {
    font: Open Sans;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 25px;
  }
`;

const Message = styled.div`
  color: #2a2929;
  text-align: left;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Name = styled.div`
  color: #000;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const CancelButton = styled.span`
  cursor: pointer;
  float: right;
  margin: 20px 20px 0px 0px;

  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const findAxisPoints = (axisValues) => {
  // Step 1: Determine the minimum and maximum values
  const minValue = Math.min(...axisValues);
  const maxValue = Math.max(...axisValues);

  // Step 2: Decide the range for the axis
  const axisRange = maxValue - minValue;

  // Step 3: Choose the number of points on the axis
  const numberOfPoints = 5; // You can adjust this based on your preference

  // Step 4: Divide the axis range into equal intervals
  const interval = axisRange / (numberOfPoints - 1);

  // Generate the points on the axis
  const axisPoints = Array.from(
    { length: numberOfPoints },
    (_, index) => minValue + index * interval
  );

  return { axisPoints, minValue, maxValue, axisRange };
};

const ClusterAnalysisGraph = ({
  job_id,
  xAxisValues = [],
  yAxisValues = [],
  sentimentLabel = {},
  sentimentColor = {},
  sentiment = [],
  reviews = [],
  type = '',
  name = [],
  date = [],
  likes = [],
  helpful = [],
  moduleType,
  dislikesCount,
  likesCount,
  circle
}) => {
  const [summary, setSummary] = useState('')
  const [reviewMessage, setReviewMessage] = useState(-1);
  const [userName, setUserName] = useState(-1);
  const [postDate, setPostDate] = useState(-1);
  const [tweetLikes, setTweetLikes] = useState(-1);
  const [helpfulCount, setHelpfulCount] = useState(-1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [likesCnt, setLikesCnt] = useState(-1);
  const [dislikes, setDislikes] = useState(-1);

  let {
    axisPoints: xPoints,
    minValue: xMinValue,
    axisRange: xAxisRange,
  } = findAxisPoints(xAxisValues);

  let {
    axisPoints: yPoints,
    minValue: yMinValue,
    axisRange: yAxisRange,
  } = findAxisPoints(yAxisValues);

  const xAxisPercentage = (pointValue) =>
    ((pointValue - xMinValue) / xAxisRange) * 100;
  const yAxisPercentage = (pointValue) =>
    ((pointValue - yMinValue) / yAxisRange) * 100;

  xPoints = xPoints.map((value) => Math.round(value));
  yPoints = yPoints.map((value) => Math.round(value));

  const handleClick = (index) => {
    if (
      index === reviewMessage ||
      index === userName ||
      index === postDate ||
      index === tweetLikes ||
      index === helpfulCount ||
      index === likesCnt ||
      index === dislikes
    ) {
      setReviewMessage(-1);
      setHelpfulCount(-1);
      setUserName(-1);
      setPostDate(-1);
      setTweetLikes(-1);
      setIsPopupOpen(false);
      setLikesCnt(-1);
      setDislikes(-1);
    } else {
      setReviewMessage(index);
      setUserName(index);
      setHelpfulCount(index);
      setPostDate(index);
      setTweetLikes(index);
      setIsPopupOpen(true);
      setLikesCnt(index);
      setDislikes(index);
    }
    setSummary('')
  };

  const label = Object.entries(sentimentLabel).map(([key, value]) => (
    <GraphLabel key={key} backgroundColor={sentimentColor[key]}>
      {value}
    </GraphLabel>
  ));

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        // alert('Text copied to clipboard!');
      },
      err => {
        alert('Failed to copy text: ', err);
      }
    );
  };

  const handleShowSummary = (data) => {
    setIsPopupOpen(true);
    setSummary(data)
    setReviewMessage(-1);
    setHelpfulCount(-1);
    setUserName(-1);
    setPostDate(-1);
    setTweetLikes(-1);
    setLikesCnt(-1);
    setDislikes(-1);
  }


  return (
    <Wrapper>
      <GraphWrapper>
        <GraphAxis>
          {xAxisValues.map((_, index) => {
            return (
              <div
                className={`clusterPoints`}
                style={{
                  left: `${xAxisPercentage(xAxisValues[index])}%`,
                  bottom: `${yAxisPercentage(yAxisValues[index])}%`,
                  background: sentimentColor[sentiment[index]],
                }}
                onClick={() => handleClick(index)}
                key={index}
              >
                {/* {xAxisValues[index]}|{yAxisValues[index]} */}
                <ReviewWrapper>
                  <Review>
                    Review - {reviews[index]}
                    <br />
                    {type} - {sentimentLabel[sentiment[index]]}
                  </Review>
                  <Pointer></Pointer>
                </ReviewWrapper>
              </div>
            );
          })}
          {job_id && circle?.length > 0 && circle?.map((cluster, clusterIndex) => {
            // if (cluster.length < 2) return null; // Skip if not a cluster
            // const centerX = cluster.reduce((sum, idx) => sum + xAxisPercentage(xAxisValues[idx]), 0) / cluster.length;
            // const centerY = cluster.reduce((sum, idx) => sum + yAxisPercentage(yAxisValues[idx]), 0) / cluster.length;
            // const radius = 10; // Adjust radius as needed

            const centerX = cluster.x;
            const centerY = cluster.y;
            const radius = cluster.radius;

            //   const centroid = cluster.reduce(
            //     (acc, point) => {
            //       acc.x += point.x;
            //       acc.y += point.y;
            //       return acc;
            //     },
            //     { x: 0, y: 0 }
            //   );
            //   centroid.x /= cluster.length;
            //   centroid.y /= cluster.length;
// console.log(centerX,centerY,radius,'converted',xAxisPercentage(centerX),yAxisPercentage(centerY));
            //   const radius = Math.max(...cluster.map(cluster => distance([centroid.x, centroid.y], [xAxisValues, yAxisValues])));
            
            return (
              <div
                className={`clusterCircles`}
                key={clusterIndex}
                style={{

                  position: 'absolute',
                  // left: `${xAxisPercentage(centerX)}%`,
                  // bottom: `${yAxisPercentage(centerY)}%`,
                  left: `${xAxisPercentage(centerX)}%`,
                  bottom: `${yAxisPercentage(centerY)}%`,
                  border: '1px solid black',
                  // backgroundColor: 'rgba(255, 0, 0, 0.3)',
                  width: radius * 20,

                  transform: `translate(-50%, 50%)`,
                  // transform: `translate(-${(radius * 20) / 2}%, ${(radius * 20) / 2}%)`,
                  height: radius * 20

                }}

                onClick={() => handleShowSummary(cluster?.summary)}
              >

                <ReviewWrapper>
                  <Review>
                    Summary - {cluster.summary}
                    <br />
                    {/* {type} - {sentimentLabel[sentiment[index]]} */}
                  </Review>
                  <Pointer2></Pointer2>
                </ReviewWrapper>

              </div>
            );
          })}

          {/* Y axis index */}
          {/* {yPoints.map((value, index) => (
            <div
              className='yAxis'
              style={{
                bottom: `${yAxisPercentage(value)}%`,
              }}
              key={index}
            >
              {value}
            </div>
          ))} */}

          {/* X axis index */}
          {/* {xPoints.map((value, index) => (
            <div
              className='xAxis'
              style={{
                left: `${xAxisPercentage(value)}%`,
              }}
              key={index}
            >
              {value}
            </div>
          ))} */}
        </GraphAxis>
      </GraphWrapper>
      <GraphLabelWrapper>{label}</GraphLabelWrapper>
      {isPopupOpen && (
        <PopUp onClose={() => setIsPopupOpen(false)}>
          {/* <span>cross</span>
          <div>{reviews[reviewMessage]}</div> */}
          <TextWrapper>
            {
              summary ?
                <div>
                  <TitleWithInfo
                    title={'Clustering Summary '}
                    infoText={`Clustering users based on their discussions on similar topics.`}
                  />
                </div> : <span>Review</span>
            }

            <Name>{name[userName]}</Name>
            {
              summary ?
                <></> :
                moduleType === 'Twitter' ? (
                  <Message>Post on {date[postDate]}</Message>
                ) : (
                  <Message>Reviewed on {date[postDate]}</Message>
                )}
            <br />
            <Message>{summary}</Message>
            <Message onClick={() => handleCopy(reviews[reviewMessage])}>{reviews[reviewMessage]}</Message>
            {
              summary ?
                <></> :
                moduleType === 'Twitter' ? (
                  <div>
                    {likes[tweetLikes] ? parseInt(likes[tweetLikes]) : 0} Likes
                  </div>
                ) : (
                  <>
                    {moduleType === 'amazon' && helpful[helpfulCount] ? (
                      <div>
                        {helpful[helpfulCount]
                          ? parseInt(helpful[helpfulCount])
                          : 0}{' '}
                        People found this helpful
                      </div>
                    ) :

                      moduleType === 'flipkart' ? (
                        <>
                          <div>{likesCnt && likesCount?.length > 0 && likesCount[likesCnt] && parseInt(likesCount[likesCnt]) > 0 ? `${parseInt(likesCount[likesCnt])} Likes` : '0 Likes'} </div>
                          <div>
                            {dislikes && dislikesCount?.length > 0 && dislikesCount[dislikes] ? `${parseInt(dislikesCount[dislikes])} Dislikes` : `0 Dislikes`}{' '}

                          </div>
                        </>
                      )
                        :
                        <></>

                    }


                  </>
                )}

            <CancelButton onClick={() => setIsPopupOpen(false)}>
              Close
            </CancelButton>
          </TextWrapper>
        </PopUp>
      )}
    </Wrapper>
  );
};

export default ClusterAnalysisGraph;
