import tenantAxiosInstance from '../utils/tenantAxiosInstance';
const API_URL = process.env.REACT_APP_BACKEND_URL;
// const version = 'v1';

const createNewAnalysis = (tenant_id, body) => {
    return tenantAxiosInstance
      .post(API_URL + `competitive/intelligence/${tenant_id}/`, body)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error('Request Error:', error);
        throw error;
      });
  };
  
  const fetchCreatedAnalysis = (tenant_id, ci_id) => {
    return tenantAxiosInstance
      .post(API_URL + `competitive/intelligence/${tenant_id}/${ci_id}/`, {})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error('Request Error:', error);
        throw error;
      });
  };

  const AnalysisUpdate = (tenant_id, user_id, ci_id) => { //active-hibernate-delete
    return tenantAxiosInstance
      .delete(API_URL + `competitive/intelligence/hibernate/${tenant_id}/${user_id}/${ci_id}/`)
      .then((response) => {
        return response.data;
      });
  };

  const competitiveIntelligenceList = (tenant_id, user_id) => { //active-hibernate-delete
    return tenantAxiosInstance
      .get(API_URL + `get/competitive_intelligence/active_list/${tenant_id}/`)
      .then((response) => {
        return response.data;
      });
  };

  
const AnalysisService = {
  createNewAnalysis,
  fetchCreatedAnalysis,
  AnalysisUpdate,
  competitiveIntelligenceList
};

export default AnalysisService;