import React from 'react';
import styled from 'styled-components';
import { StarIcon } from '../SvgIcon/CommonSvgIcons';

const BarGraphContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 30px;
  height: 200px; /* Adjust height as needed */
  padding: 10px; /* Adjust padding */
  ${'' /* margin-left: 50px; */}
  justify-content: center;
`;

const Bar = styled.div`
  width: 50px; /* Adjust width of each bar */
  display: flex;
  flex-direction: column-reverse; /* Reverse the column direction to make bars grow from bottom to top */
  align-items: center;
  position: relative;

  &>div: first-child {
    border-radius: 3px 0 0 3px;
  }
`;

const BarValue = styled.span`
  position: absolute;
  top: -20px; /* Adjust top position */
  font-size: 14px; /* Adjust font size */
  color: #636363;
  font-family: Montserrat;
`;

const FilledBar = styled.div`
  height: ${(props) => props.barHeight};
  width: 100%;
  background: red;
`;

const BarLabel = styled.span`
  position: absolute;
  bottom: -30px; /* Adjust bottom position */
  font-size: 14px; /* Adjust font size */
  white-space: nowrap; /* Prevent label text from wrapping */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis;
  width: 60px;
  text-align:center;

  color: #636363;
  font-family: Montserrat;
`;

const VerticalBarGraph = ({ data, type = '',starRating }) => {
  // Sample data for the bars

  const sortedData = data.sort((a, b) => b.value - a.value);

  const maxValue = Math.max(...sortedData.map((item) => item.value));
  const multiplier = 180 / maxValue;
  return (
    <BarGraphContainer className="pdf-page" style={{  pageBreakInside: 'avoid'}}>
      {data.map((item, index) => (
        <Bar key={index}>
          <div
            style={{
              height: `${Math.floor(item.value * multiplier) + 2}px`,
              backgroundColor: item.fill || '#353cf7',
              width: '100%',
            }}
          ></div>
          <BarValue>{item.value}<span>{type}</span> </BarValue>
          <BarLabel>{item.name}</BarLabel>
        </Bar>
      ))}
    </BarGraphContainer>
  );
};

export default VerticalBarGraph;
