export const location = [
  { label: 'Amsterdam, Netherlands', value: 'Amsterdam' },
  { label: 'Paris, France', value: 'Paris' },
  { label: 'New York, USA', value: 'New York' },
  { label: 'London, UK', value: 'London' },
  { label: 'Berlin, Germany', value: 'Berlin' },
  { label: 'Sydney, Australia', value: 'Sydney' },
  { label: 'Toronto, Canada', value: 'Toronto' },
  { label: 'Rome, Italy', value: 'Rome' },
  { label: 'Dubai, UAE', value: 'Dubai' },
  { label: 'Shanghai, China', value: 'Shanghai' },
  { label: 'Moscow, Russia', value: 'Moscow' },
  { label: 'São Paulo, Brazil', value: 'São Paulo' },
  { label: 'Los Angeles, USA', value: 'Los Angeles' },
  { label: 'Mumbai, India', value: 'Mumbai' },
  { label: 'Cape Town, South Africa', value: 'Cape Town' },
  { label: 'Buenos Aires, Argentina', value: 'Buenos Aires' },
  { label: 'Istanbul, Turkey', value: 'Istanbul' },
  { label: 'Mexico City, Mexico', value: 'Mexico City' },
  { label: 'Lagos, Nigeria', value: 'Lagos' },
  { label: 'Hong Kong, Hong Kong', value: 'Hong Kong' },
  { label: 'Zurich, Switzerland', value: 'Zurich' },
  { label: 'Prague, Czech Republic', value: 'Prague' },
  { label: 'Copenhagen, Denmark', value: 'Copenhagen' },
  { label: 'Edinburgh, UK', value: 'Edinburgh' },
  { label: 'Manchester, UK', value: 'Manchester' },
  { label: 'Birmingham, UK', value: 'Birmingham' },
  { label: 'Glasgow, UK', value: 'Glasgow' },
  { label: 'Cardiff, UK', value: 'Cardiff' },
  { label: 'Bristol, UK', value: 'Bristol' },
  { label: 'Leeds, UK', value: 'Leeds' },
  { label: 'Liverpool, UK', value: 'Liverpool' },
  { label: 'Sheffield, UK', value: 'Sheffield' },
  { label: 'Nottingham, UK', value: 'Nottingham' },
  { label: 'Leicester, UK', value: 'Leicester' },
  { label: 'Coventry, UK', value: 'Coventry' },
  { label: 'Swansea, UK', value: 'Swansea' },
  { label: 'Kingston upon Hull, UK', value: 'Kingston upon Hull' },
  { label: 'Aberdeen, UK', value: 'Aberdeen' },
  { label: 'Plymouth, UK', value: 'Plymouth' },
  { label: 'Stoke-on-Trent, UK', value: 'Stoke-on-Trent' },
  { label: 'Wolverhampton, UK', value: 'Wolverhampton' },
  { label: 'Bradford, UK', value: 'Bradford' },
  { label: 'Southampton, UK', value: 'Southampton' },
  { label: 'Reading, UK', value: 'Reading' },
  { label: 'Newcastle upon Tyne, UK', value: 'Newcastle upon Tyne' },
  { label: 'Portsmouth, UK', value: 'Portsmouth' },
  { label: 'Norwich, UK', value: 'Norwich' },
  { label: 'Oxford, UK', value: 'Oxford' },
  { label: 'Cambridge, UK', value: 'Cambridge' },
  { label: 'Dundee, UK', value: 'Dundee' },
  { label: 'Luton, UK', value: 'Luton' },
  { label: 'Milton Keynes, UK', value: 'Milton Keynes' },
  { label: 'Sunderland, UK', value: 'Sunderland' },
  { label: 'Brighton, UK', value: 'Brighton' },
  { label: 'Belfast, UK', value: 'Belfast' },
  { label: 'Bath, UK', value: 'Bath' },
  { label: 'Chester, UK', value: 'Chester' },
  { label: 'Exeter, UK', value: 'Exeter' },
  { label: 'Gloucester, UK', value: 'Gloucester' },
  { label: 'Lancaster, UK', value: 'Lancaster' },
  { label: 'Lincoln, UK', value: 'Lincoln' },
  { label: 'Winchester, UK', value: 'Winchester' },
  { label: 'Worcester, UK', value: 'Worcester' },
  { label: 'York, UK', value: 'York' },
  { label: 'Brisbane, Australia', value: 'Brisbane' },
  { label: 'Perth, Australia', value: 'Perth' },
  { label: 'Adelaide, Australia', value: 'Adelaide' },
  { label: 'Vienna, Austria', value: 'Vienna' },
  { label: 'Brussels, Belgium', value: 'Brussels' },
  { label: 'Sofia, Bulgaria', value: 'Sofia' },
  { label: 'Zagreb, Croatia', value: 'Zagreb' },
  { label: 'Tallinn, Estonia', value: 'Tallinn' },
  { label: 'Helsinki, Finland', value: 'Helsinki' },
  { label: 'Athens, Greece', value: 'Athens' },
  { label: 'Budapest, Hungary', value: 'Budapest' },
  { label: 'Reykjavik, Iceland', value: 'Reykjavik' },
  { label: 'Dublin, Ireland', value: 'Dublin' },
  { label: 'Riga, Latvia', value: 'Riga' },
  { label: 'Vilnius, Lithuania', value: 'Vilnius' },
  { label: 'Valletta, Malta', value: 'Valletta' },
  { label: 'Chisinau, Moldova', value: 'Chisinau' },
  { label: 'Monaco, Monaco', value: 'Monaco' },
  { label: 'Podgorica, Montenegro', value: 'Podgorica' },
  { label: 'Oslo, Norway', value: 'Oslo' },
  { label: 'Warsaw, Poland', value: 'Warsaw' },
  { label: 'Lisbon, Portugal', value: 'Lisbon' },
  { label: 'Bucharest, Romania', value: 'Bucharest' },
  { label: 'Belgrade, Serbia', value: 'Belgrade' },
  { label: 'Bratislava, Slovakia', value: 'Bratislava' },
  { label: 'Ljubljana, Slovenia', value: 'Ljubljana' },
  { label: 'Madrid, Spain', value: 'Madrid' },
  { label: 'Stockholm, Sweden', value: 'Stockholm' },
  { label: 'Bern, Switzerland', value: 'Bern' },
  { label: 'Kiev, Ukraine', value: 'Kiev' },
  { label: 'Algiers, Algeria', value: 'Algiers' },
  { label: 'Luanda, Angola', value: 'Luanda' },
  { label: 'Gaborone, Botswana', value: 'Gaborone' },
  { label: 'Ouagadougou, Burkina Faso', value: 'Ouagadougou' },
  { label: 'Bujumbura, Burundi', value: 'Bujumbura' },
  { label: 'Yaoundé, Cameroon', value: 'Yaoundé' },
  { label: 'Praia, Cape Verde', value: 'Praia' },
  { label: 'Bangui, Central African Republic', value: 'Bangui' },
  { label: 'NʻDjamena, Chad', value: 'NʻDjamena' },
  { label: 'Moroni, Comoros', value: 'Moroni' },
  { label: 'Kinshasa, DR Congo', value: 'Kinshasa' },
  { label: 'Brazzaville, Republic of the Congo', value: 'Brazzaville' },
  { label: 'Djibouti, Djibouti', value: 'Djibouti' },
  { label: 'Cairo, Egypt', value: 'Cairo' },
  { label: 'Malabo, Equatorial Guinea', value: 'Malabo' },
  { label: 'Asmara, Eritrea', value: 'Asmara' },
  { label: 'Addis Ababa, Ethiopia', value: 'Addis Ababa' },
  { label: 'Libreville, Gabon', value: 'Libreville' },
  { label: 'Banjul, Gambia', value: 'Banjul' },
  { label: 'Accra, Ghana', value: 'Accra' },
  { label: 'Conakry, Guinea', value: 'Conakry' },
  { label: 'Bissau, Guinea-Bissau', value: 'Bissau' },
  { label: 'Abidjan, Ivory Coast', value: 'Abidjan' },
  { label: 'Nairobi, Kenya', value: 'Nairobi' },
  { label: 'Maseru, Lesotho', value: 'Maseru' },
  { label: 'Monrovia, Liberia', value: 'Monrovia' },
  { label: 'Tripoli, Libya', value: 'Tripoli' },
  { label: 'Antananarivo, Madagascar', value: 'Antananarivo' },
  { label: 'Lilongwe, Malawi', value: 'Lilongwe' },
  { label: 'Bamako, Mali', value: 'Bamako' },
  { label: 'Nouakchott, Mauritania', value: 'Nouakchott' },
  { label: 'Port Louis, Mauritius', value: 'Port Louis' },
  { label: 'Rabat, Morocco', value: 'Rabat' },
  { label: 'Maputo, Mozambique', value: 'Maputo' },
  { label: 'Windhoek, Namibia', value: 'Windhoek' },
  { label: 'Niamey, Niger', value: 'Niamey' },
  { label: 'Abuja, Nigeria', value: 'Abuja' },
  { label: 'Kigali, Rwanda', value: 'Kigali' },
  { label: 'Dakar, Senegal', value: 'Dakar' },
  { label: 'Freetown, Sierra Leone', value: 'Freetown' },
  { label: 'Mogadishu, Somalia', value: 'Mogadishu' },
  { label: 'Pretoria, South Africa', value: 'Pretoria' },
  { label: 'Juba, South Sudan', value: 'Juba' },
  { label: 'Khartoum, Sudan', value: 'Khartoum' },
  { label: 'Mbabane, Eswatini', value: 'Mbabane' },
  { label: 'Dodoma, Tanzania', value: 'Dodoma' },
  { label: 'Lome, Togo', value: 'Lome' },
  { label: 'Tunis, Tunisia', value: 'Tunis' },
  { label: 'Kampala, Uganda', value: 'Kampala' },
  { label: 'Lusaka, Zambia', value: 'Lusaka' },
  { label: 'Harare, Zimbabwe', value: 'Harare' },
  { label: 'Jerusalem, Israel', value: 'Jerusalem' },
  { label: 'Riyadh, Saudi Arabia', value: 'Riyadh' },
  { label: 'Muscat, Oman', value: 'Muscat' },
  { label: 'Baghdad, Iraq', value: 'Baghdad' },
  { label: 'Kuwait City, Kuwait', value: 'Kuwait City' },
  { label: 'Damascus, Syria', value: 'Damascus' },
  { label: 'Beirut, Lebanon', value: 'Beirut' },
  { label: 'Amman, Jordan', value: 'Amman' },
  { label: 'Tehran, Iran', value: 'Tehran' },
  { label: 'Ankara, Turkey', value: 'Ankara' },
  { label: 'Nicosia, Cyprus', value: 'Nicosia' },
  { label: 'Kabul, Afghanistan', value: 'Kabul' },
  { label: 'Yerevan, Armenia', value: 'Yerevan' },
  { label: 'Baku, Azerbaijan', value: 'Baku' },
  { label: 'Dhaka, Bangladesh', value: 'Dhaka' },
  { label: 'Thimphu, Bhutan', value: 'Thimphu' },
  { label: 'Naypyidaw, Myanmar', value: 'Naypyidaw' },
  { label: 'Phnom Penh, Cambodia', value: 'Phnom Penh' },
  { label: 'Beijing, China', value: 'Beijing' },
  { label: 'Tbilisi, Georgia', value: 'Tbilisi' },
  { label: 'New Delhi, India', value: 'New Delhi' },
  { label: 'Jakarta, Indonesia', value: 'Jakarta' },
  { label: 'Tokyo, Japan', value: 'Tokyo' },
  { label: 'Astana, Kazakhstan', value: 'Astana' },
  { label: 'Bishkek, Kyrgyzstan', value: 'Bishkek' },
  { label: 'Vientiane, Laos', value: 'Vientiane' },
  { label: 'Kuala Lumpur, Malaysia', value: 'Kuala Lumpur' },
  { label: 'Malé, Maldives', value: 'Malé' },
  { label: 'Ulaanbaatar, Mongolia', value: 'Ulaanbaatar' },
  { label: 'Kathmandu, Nepal', value: 'Kathmandu' },
  { label: 'Pyongyang, North Korea', value: 'Pyongyang' },
  { label: 'Islamabad, Pakistan', value: 'Islamabad' },
  { label: 'Manila, Philippines', value: 'Manila' },
  { label: 'Singapore, Singapore', value: 'Singapore' },
  { label: 'Seoul, South Korea', value: 'Seoul' },
  { label: 'Colombo, Sri Lanka', value: 'Colombo' },
  { label: 'Dushanbe, Tajikistan', value: 'Dushanbe' },
  { label: 'Bangkok, Thailand', value: 'Bangkok' },
  { label: 'Ashgabat, Turkmenistan', value: 'Ashgabat' },
  { label: 'Tashkent, Uzbekistan', value: 'Tashkent' },
  { label: 'Hanoi, Vietnam', value: 'Hanoi' },
  { label: 'Sanaa, Yemen', value: 'Sanaa' },
  { label: 'Canberra, Australia', value: 'Canberra' },
  { label: 'Wellington, New Zealand', value: 'Wellington' },
  { label: 'Suva, Fiji', value: 'Suva' },
  { label: 'Port Moresby, Papua New Guinea', value: 'Port Moresby' },
  { label: 'Apia, Samoa', value: 'Apia' },
  { label: 'Nukuʻalofa, Tonga', value: 'Nukuʻalofa' },
  { label: 'Funafuti, Tuvalu', value: 'Funafuti' },
  { label: 'Port Vila, Vanuatu', value: 'Port Vila' },
  { label: 'Honiara, Solomon Islands', value: 'Honiara' },
  { label: 'Nouméa, New Caledonia', value: 'Nouméa' },
  { label: 'Papeete, French Polynesia', value: 'Papeete' },
  { label: 'Pago Pago, American Samoa', value: 'Pago Pago' },
  { label: 'Guam, Guam', value: 'Guam' },
  { label: 'Majuro, Marshall Islands', value: 'Majuro' },
  { label: 'Palikir, Micronesia', value: 'Palikir' },
  { label: 'Yaren, Nauru', value: 'Yaren' },
  { label: 'Alofi, Niue', value: 'Alofi' },
  { label: 'Hobart, Australia', value: 'Hobart' },
  { label: 'Graz, Austria', value: 'Graz' },
    { label: 'Ghent, Belgium', value: 'Ghent' },
    { label: 'Varna, Bulgaria', value: 'Varna' },
    { label: 'Pula, Croatia', value: 'Pula' },
    { label: 'Paphos, Cyprus', value: 'Paphos' },
    { label: 'Tartu, Estonia', value: 'Tartu' },
    { label: 'Oulu, Finland', value: 'Oulu' },
    { label: 'Patras, Greece', value: 'Patras' },
    { label: 'Debrecen, Hungary', value: 'Debrecen' },
    { label: 'Akureyri, Iceland', value: 'Akureyri' },
    { label: 'Cork, Ireland', value: 'Cork' },
    { label: 'Daugavpils, Latvia', value: 'Daugavpils' },
    { label: 'Kaunas, Lithuania', value: 'Kaunas' },
    { label: 'Gozo, Malta', value: 'Gozo' },
    { label: 'Tiraspol, Moldova', value: 'Tiraspol' },
    { label: 'Kotor, Montenegro', value: 'Kotor' },
    { label: 'Bergen, Norway', value: 'Bergen' },
    { label: 'Gdansk, Poland', value: 'Gdansk' },
    { label: 'Porto, Portugal', value: 'Porto' },
    { label: 'Cluj-Napoca, Romania', value: 'Cluj-Napoca' },
    { label: 'Novi Sad, Serbia', value: 'Novi Sad' },
    { label: 'Kosice, Slovakia', value: 'Kosice' },
    { label: 'Maribor, Slovenia', value: 'Maribor' },
    { label: 'Bilbao, Spain', value: 'Bilbao' },
    { label: 'Gothenburg, Sweden', value: 'Gothenburg' },
    { label: 'Basel, Switzerland', value: 'Basel' },
    { label: 'Lviv, Ukraine', value: 'Lviv' },
    { label: 'Oran, Algeria', value: 'Oran' },
    { label: 'Lobito, Angola', value: 'Lobito' },
    { label: 'Francistown, Botswana', value: 'Francistown' },
    { label: 'Bobo-Dioulasso, Burkina Faso', value: 'Bobo-Dioulasso' },
    { label: 'Gitega, Burundi', value: 'Gitega' },
    { label: 'Douala, Cameroon', value: 'Douala' },
    { label: 'Mindelo, Cape Verde', value: 'Mindelo' },
    { label: 'Bouar, Central African Republic', value: 'Bouar' },
    { label: 'Moundou, Chad', value: 'Moundou' },
    { label: 'Mutsamudu, Comoros', value: 'Mutsamudu' },
    { label: 'Lubumbashi, DR Congo', value: 'Lubumbashi' },
    { label: 'Pointe-Noire, Republic of the Congo', value: 'Pointe-Noire' },
    { label: 'Ali Sabieh, Djibouti', value: 'Ali Sabieh' },
    { label: 'Alexandria, Egypt', value: 'Alexandria' },
    { label: 'Bata, Equatorial Guinea', value: 'Bata' },
    { label: 'Keren, Eritrea', value: 'Keren' },
    { label: 'Dire Dawa, Ethiopia', value: 'Dire Dawa' },
    { label: 'Franceville, Gabon', value: 'Franceville' },
    { label: 'Basse Santa Su, Gambia', value: 'Basse Santa Su' },
    { label: 'Kumasi, Ghana', value: 'Kumasi' },
    { label: 'Nzérékoré, Guinea', value: 'Nzérékoré' },
    { label: 'Bafatá, Guinea-Bissau', value: 'Bafatá' },
    { label: 'Bouaké, Ivory Coast', value: 'Bouaké' },
    { label: 'Mombasa, Kenya', value: 'Mombasa' },
    { label: 'Teyateyaneng, Lesotho', value: 'Teyateyaneng' },
    { label: 'Gbarnga, Liberia', value: 'Gbarnga' },
    { label: 'Benghazi, Libya', value: 'Benghazi' },
    { label: 'Toamasina, Madagascar', value: 'Toamasina' },
    { label: 'Blantyre, Malawi', value: 'Blantyre' },
    { label: 'Timbuktu, Mali', value: 'Timbuktu' },
    { label: 'Nouadhibou, Mauritania', value: 'Nouadhibou' },
    { label: 'Curepipe, Mauritius', value: 'Curepipe' },
    { label: 'Casablanca, Morocco', value: 'Casablanca' },
    { label: 'Nacala, Mozambique', value: 'Nacala' },
    { label: 'Walvis Bay, Namibia', value: 'Walvis Bay' },
    { label: 'Maradi, Niger', value: 'Maradi' }, 
    { label: 'Kano, Nigeria', value: 'Kano' },
    { label: 'Butare, Rwanda', value: 'Butare' },
    { label: 'Saint-Louis, Senegal', value: 'Saint-Louis' },
    { label: 'Victoria, Seychelles', value: 'Victoria' },
    { label: 'Bo, Sierra Leone', value: 'Bo' },
    { label: 'Hargeisa, Somalia', value: 'Hargeisa' },
    { label: 'Durban, South Africa', value: 'Durban' },
    { label: 'Wau, South Sudan', value: 'Wau' },
    { label: 'Port Sudan, Sudan', value: 'Port Sudan' },
    { label: 'Manzini, Eswatini', value: 'Manzini' },
    { label: 'Mwanza, Tanzania', value: 'Mwanza' },
    { label: 'Sokodé, Togo', value: 'Sokodé' },
    { label: 'Sfax, Tunisia', value: 'Sfax' },
    { label: 'Mbarara, Uganda', value: 'Mbarara' },
    { label: 'Kitwe, Zambia', value: 'Kitwe' },
    { label: 'Bulawayo, Zimbabwe', value: 'Bulawayo' },
    { label: 'Abu Dhabi, UAE', value: 'Abu Dhabi' },
    { label: 'Doha, Qatar', value: 'Doha' },
    { label: 'Manama, Bahrain', value: 'Manama' },
    { label: 'Haifa, Israel', value: 'Haifa' },
    { label: 'Jeddah, Saudi Arabia', value: 'Jeddah' },
    { label: 'Salalah, Oman', value: 'Salalah' },
    { label: 'Basra, Iraq', value: 'Basra' },
    { label: 'Hawalli, Kuwait', value: 'Hawalli' },
    { label: 'Aleppo, Syria', value: 'Aleppo' },
    { label: 'Tripoli, Lebanon', value: 'Tripoli' },
    { label: 'Zarqa, Jordan', value: 'Zarqa' },
    { label: 'Mashhad, Iran', value: 'Mashhad' },
    { label: 'Izmir, Turkey', value: 'Izmir' },
    { label: 'Larnaca, Cyprus', value: 'Larnaca' },
    { label: 'Herat, Afghanistan', value: 'Herat' },
    { label: 'Gyumri, Armenia', value: 'Gyumri' },
    { label: 'Ganja, Azerbaijan', value: 'Ganja' },
    { label: 'Yangon, Myanmar', value: 'Yangon' },
    { label: 'Siem Reap, Cambodia', value: 'Siem Reap' },
    { label: 'Guangzhou, China', value: 'Guangzhou' },
    { label: 'Batumi, Georgia', value: 'Batumi' },
    { label: 'Bengaluru, India', value: 'Bengaluru' },
    { label: 'Surabaya, Indonesia', value: 'Surabaya' },
    { label: 'Osaka, Japan', value: 'Osaka' },
    { label: 'Almaty, Kazakhstan', value: 'Almaty' },
    { label: 'Osh, Kyrgyzstan', value: 'Osh' },  
  { label: 'Chennai, India', value: 'Chennai' },
  { label: 'Kolkata, India', value: 'Kolkata' },
  { label: 'Hyderabad, India', value: 'Hyderabad' },
  { label: 'Pune, India', value: 'Pune' },
  { label: 'Ahmedabad, India', value: 'Ahmedabad' },
  { label: 'Surat, India', value: 'Surat' },
  { label: 'Jaipur, India', value: 'Jaipur' },
  { label: 'Lucknow, India', value: 'Lucknow' },
  { label: 'Kanpur, India', value: 'Kanpur' },
  { label: 'Nagpur, India', value: 'Nagpur' },
  { label: 'Indore, India', value: 'Indore' },
  { label: 'Thane, India', value: 'Thane' },
  { label: 'Bhopal, India', value: 'Bhopal' },
  { label: 'Visakhapatnam, India', value: 'Visakhapatnam' },
  { label: 'Patna, India', value: 'Patna' },
  { label: 'Vadodara, India', value: 'Vadodara' },
  { label: 'Ghaziabad, India', value: 'Ghaziabad' },
  { label: 'Kyoto, Japan', value: 'Kyoto' },
  { label: 'Yokohama, Japan', value: 'Yokohama' },
  { label: 'Nagoya, Japan', value: 'Nagoya' },
  { label: 'Sapporo, Japan', value: 'Sapporo' },
  { label: 'Kobe, Japan', value: 'Kobe' },
  { label: 'Fukuoka, Japan', value: 'Fukuoka' },
  { label: 'Hiroshima, Japan', value: 'Hiroshima' },
  { label: 'Sendai, Japan', value: 'Sendai' },
  { label: 'Chiba, Japan', value: 'Chiba' },
  { label: 'Kitakyushu, Japan', value: 'Kitakyushu' },
  { label: 'Saitama, Japan', value: 'Saitama' },
  { label: 'Kawasaki, Japan', value: 'Kawasaki' },
  { label: 'Niigata, Japan', value: 'Niigata' },
  { label: 'Okayama, Japan', value: 'Okayama' },
  { label: 'Shizuoka, Japan', value: 'Shizuoka' },
  { label: 'Sagamihara, Japan', value: 'Sagamihara' },
  { label: 'Hamamatsu, Japan', value: 'Hamamatsu' },
  { label: 'Kumamoto, Japan', value: 'Kumamoto' },
  { label: 'Chicago, USA', value: 'Chicago' },
  { label: 'Houston, USA', value: 'Houston' },
  { label: 'Phoenix, USA', value: 'Phoenix' },
  { label: 'Philadelphia, USA', value: 'Philadelphia' },
  { label: 'San Antonio, USA', value: 'San Antonio' },
  { label: 'San Diego, USA', value: 'San Diego' },
  { label: 'Dallas, USA', value: 'Dallas' },
  { label: 'San Jose, USA', value: 'San Jose' },
  { label: 'Austin, USA', value: 'Austin' },
  { label: 'Jacksonville, USA', value: 'Jacksonville' },
  { label: 'Fort Worth, USA', value: 'Fort Worth' },
  { label: 'Columbus, USA', value: 'Columbus' },
  { label: 'San Francisco, USA', value: 'San Francisco' },
  { label: 'Charlotte, USA', value: 'Charlotte' },
  { label: 'Indianapolis, USA', value: 'Indianapolis' },
  { label: 'Seattle, USA', value: 'Seattle' },
  { label: 'Denver, USA', value: 'Denver' },
  { label: 'Washington, D.C., USA', value: 'Washington, D.C.' }
]