import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: left;
  //position: relative;
  width: 100%;

  & > * {
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
  }
`;

const TextDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border:1px solid rgb(162, 162, 162);
  border-radius:15px;
  margin-top:10px;
  & > * {
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
  }

  & > span {
    margin-left: -40px;
    color: #636363;
  }
`;



const InputTitle = styled.label`
  padding: 8px;
  width: 100%;
`;

const ErrorMessage = styled.div`
  padding: 2px 10px;

  color: #d74748;
  font-family: Montserrat;
  font-size: 14px; 
  font-weight: 500;
  line-height: normal;
`;

const TextArea = ({

    setChange,
    label,
    require,
    type,
    name,
    placeholder,
    value,
    onChange,
    customStyle,
    characterLimit,
    maxLength,
    limit,
    readOnly,
    error = '',
}) => {
    const [remainingCharacters, setRemainingCharacters] =
        useState(characterLimit);


    const [message, setMessage] = useState('');

    // Function to handle textarea input
    const handleInput = (event) => {
        if (setChange) {
            if (event.target.value) {
                const scrollHeight = event.target.scrollHeight;
                // Update the height of the textarea dynamically
                event.target.style.height = scrollHeight + 'px';

            } else {

                event.target.style.height = '50px';
            }
            setMessage(event?.target?.value);
            onChange(event);
        }

    };





    useEffect(() => {
        if (value) {
            setRemainingCharacters(characterLimit - value.length);
        }
    }, [value, characterLimit]);

    return (
        <Wrapper>
            {label && (
                <>
                    <InputTitle>
                        {label}
                        {require && <span style={{ color: '#FC6758' }}> *</span>}
                    </InputTitle>
                    <br />
                </>
            )}
            <TextDiv>
                <textarea


                    type={type || 'text'}
                    name={name || ''}
                    id="message-box"
                    placeholder={placeholder}
                    onChange={handleInput}
                    style={{
                        resize: 'none',
                        width: '91%',
                        minHeight: '55px',
                        padding: '8px 12px',
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                        border: 'none',
                        borderRadius: '15px',
                        alignContent: 'center',
                        outline: 'none'
                    }}


                    value={value}
                    maxLength={maxLength}
                    limit={limit}
                    error={error}
                    readOnly={readOnly}
                />

                {value && characterLimit ? (
                    <span>{remainingCharacters}</span>
                ) : (
                    <span>{limit}</span>
                )}
            </TextDiv>
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </Wrapper>
    );
};

export default TextArea;
