import styled from 'styled-components';
import NewSideBar from '../../components/NewSideBar/NewSideBar';
import { useParams } from 'react-router-dom';
import HomePage from './homePage';
// import TopicBuilder from './MainContent/QueryBuilder/TopicBuilder';
import ActiveTopic from '../ActiveTopic/ActiveTopic';

import { useDispatch, useSelector } from 'react-redux';
import { activeTopicList } from '../../store/redux/slices/topicSlice';
// import AddNewTopic from '../TopicBuilder/MainContent/AddNewTopic/AddNewTopic';
import { CreateChatterIcon } from '../../Common/SvgIcon/CommonSvgIcons';
import CreateComparison from './createComparison';
import { useEffect, useState } from 'react';
import { competitiveIntelligenceList } from '../../store/redux/slices/competitiveIntelligenceSlice';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 1fr;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: auto;
  // background:red;
`;

const CompetitiveIntelligence = () => {
  const { screenId } = useParams();
  const [AnalysisList, setAnalysisList] = useState([])

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(competitiveIntelligenceList()).then(
      (res) => {
        setAnalysisList(res.payload.data)
      }
    );
  }, []);


  /* const subTabs = [
    {
      id: '1a',
      val: 'New Topic',
      type: 'add', //If you want to add icon in the subTab then add type: 'add'
      path: '/topic/create',
    },
  ]; */

  const activeSubTabs = [];
  const hibernatedSubTabs = [];

  const TabScreen = {
    home: <HomePage list={AnalysisList}/>,
    create: <CreateComparison/>,
    view: <ActiveTopic />, //replace with analysis view
  };

  AnalysisList?.forEach((item) => {
    // TabScreen[`job_${item.job_id}`] = <Activeitem job_id={item.job_id} />;

    const subTab = {
      id: item.ci_id,
      val: item.chatter_topic_title_1,
      path: `/competitiveIntelligence/ci-${item.ci_id}`,
      // channel:analysis.channel
    };
    if (item.is_active === 0) {
      activeSubTabs.push(subTab);
    } else if(item.is_active === 1) {
      hibernatedSubTabs.push(subTab);
    }
  });

  const tabs = [
    {
      id: 1,
      val: 'Active',
      /* path: '/chatter/home', */
      subTabs: activeSubTabs,
    },
    {
      id:1,
      val: 'Hibernated',
      subTabs: hibernatedSubTabs,
    }
  ];

  //To set active tab of the sidebar
  const generateActiveTab = () => {
    if (screenId === 'home') {
      return 1;
    } else if (screenId === 'create') {
      return { tab: 1, subTab: '1a' };
    } else if (screenId === 'view') {
      return { tab: 1, subTab: '1a' };
    }
    for (let i = 0; i < activeSubTabs.length; i++) {
      if (screenId === `job_${activeSubTabs[i].id}`) {
        return { tab: 1, subTab: activeSubTabs[i].id };
      }
    }
    for (let i = 0; i < hibernatedSubTabs.length; i++) {
      if (screenId === `job_${hibernatedSubTabs[i].id}`) {
        return { tab: 2, subTab: hibernatedSubTabs[i].id };
      }
    }
  };

  return (
    <Wrapper>
      <NewSideBar
        headerName='Competitive Analysis'
        tabs={tabs}
        initialActiveTab={generateActiveTab()}
        buttonTitle={'Compare'}
      />
      {TabScreen[screenId]}
    </Wrapper>
  );
};

export default CompetitiveIntelligence;
