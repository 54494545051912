import React, { useEffect, useState } from 'react';
import {
  TopicDiv,
  NewTopic,
  NewButton,
  ActiveTopic,
  NewTopicDesc,
  Desc,
  Wrapper,
  Heading,
  ActiveTopicDiv,
  NewTopicSpan,
} from '../TopicBuilder/MainContent/HomePage/HomePage.styled';

import { useDispatch, useSelector } from 'react-redux';
import { activeTopicList, setPopUpActive } from '../../store/redux/slices/topicSlice';
import { useNavigate } from 'react-router-dom';
import { competitiveIntelligenceList } from '../../store/redux/slices/competitiveIntelligenceSlice';
import styled from 'styled-components';

const ActiveAnalysisDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #353cf75e;
  ${'' /* width: 131px; */}
  ${'' /* height: 84px; */}
  border-radius: 10px;

  font-family: Montserrat;
  font-weight: 500;
  font-size: 18px;
  padding: 15px 20px;
`;


function HomePage({list}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAnalysisClick = (ci_id) => {
    navigate('/competitiveIntelligence/ci-' + ci_id);
  };

  const handleChangePage = () => {
    dispatch(setPopUpActive(true))
    navigate('/competitiveIntelligence/create')
  }

  return (
    <Wrapper>
      <TopicDiv>Competitive Intelligence</TopicDiv>
      <Desc>Discover brand insights, craft custom reports</Desc>
      <NewTopic>
        <NewTopicSpan>the advanced Competitive Intelligence</NewTopicSpan>
        <NewTopicDesc>
          Our advanced query builder allows users to create targeted topic
          requests with ease. Seamlessly explore a wide array of filtering
          options, including keywords, hashtags, user mentions, exclusions, and
          more, to fulfil your diverse data needs.
        </NewTopicDesc>
        <NewButton onClick={() => handleChangePage()}>
          Compare
        </NewButton>
      </NewTopic>
      <ActiveTopic>
        <Heading>Active Comparisons</Heading>
        <div className='topicWrapper'>
          {list?.map((el) => (
            <ActiveAnalysisDiv
              key={el.job_id}
              onClick={() => handleAnalysisClick(el.ci_id)}
            >
              <p>{el.chatter_topic_title_1}</p>
              <hr style={{width: '90%',color:'white', display: 'inline-block', verticalAlign: 'middle', margin: '0 10px',border: 'none', borderTop: '1px solid #36363c'}}></hr>
              <p>{el.chatter_topic_title_2}</p>
            </ActiveAnalysisDiv>
          ))}
        </div>
      </ActiveTopic>
    </Wrapper>
  );
}

export default HomePage;
