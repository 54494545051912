import { Close, Download } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCreatedTopic } from '../../store/redux/slices/topicSlice';
import DownloadPDFReport from './DownloadPDFReport';
import styled, { css, keyframes } from 'styled-components';
import DownloadFile from './Download/Download';
import { exportMultipleChartsToPdf } from './Download/Util';
import html2pdf from 'html2pdf.js';

const PDFWrapper = styled.div`
  position: absolute;
  width: 98vw;
  height: 100vh;

  top: 0;
`;

export const fadeInAnimation = keyframes`


  from {
    
    transform: translateY(100%) scaleY(1);
  }
  to {
    transform: translateY(0%) scaleY(1);
  }
`;

const SplitScreenWrapper = styled.div`

height: 185px;
padding: 20px 30px;
border: 1px solid black;
border-radius: 10px 10px 0px 0px;
box-shadow:6px 3px 19px #080808;
overflow: auto;

animation: ${fadeInAnimation} 0.3s ease-in-out; /* Use the fade-in animation */
`;



const SplitScreenReports = ({
    data,
    setShowSplitScreen,
    getAllReportData
}) => {
    
    const [download, setDownload] = useState(false)
    const contentDivRef = useRef(null);
    const [ AnalysisFilter ,setAnalysisFilter] = useState('')
    const [openPDF, setOpenPDF] = useState(false);
    function convertDateTime(inputDateTime) {
        let dtObject = new Date(inputDateTime);

        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        let formattedDate =
            monthNames[dtObject.getMonth()] +
            ' ' +
            dtObject.getDate() +
            ' at ' +
            (dtObject.getHours() % 12 || 12) +
            ':' +
            (dtObject.getMinutes() < 10 ? '0' : '') +
            dtObject.getMinutes() +
            ' ' +
            (dtObject.getHours() >= 12 ? 'PM' : 'AM');
        return formattedDate;
    }



    const handleGeneratePdf = (refsArray) => {
        // setAnalysisFilter( )
        if (getAllReportData?.length > 0 ) {
            setDownload(true)
            const element = document.getElementById('element-to-convert');

            const options = {
                filename: 'myfile.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' }
            };
            setTimeout(() => {


                // Call the html2pdf function with the selected element and options
                html2pdf().from(element).set(options).save()
                .then((data)=> setDownload(false))

                
            }, 5000); // 3000 milliseconds = 3 seconds

        }

    }
    
    return (
        <SplitScreenWrapper>
            <div style={{
                display: "flex",
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '25px'
            }}>
                <h3 style={{
                    fontSize: '18px',
                    margin: '0px',
                    fontWeight: '600'
                }}>Brand Intelligence Report</h3>
                <Close style={{
                    border: '1px solid black',
                    borderRadius: '50%',
                    padding: '2px'
                }}
                    onClick={() => setShowSplitScreen(false)}


                />
            </div>

            <div style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: 'baseline'
            }}>
                <h5 style={{
                    fontSize: '18px',
                    margin: '0px',
                    fontWeight: '600',
                    marginBottom: '5px'

                }}>{getAllReportData.length > 0 && getAllReportData[0]?.outlook_report?.is_topic ?
                    getAllReportData.length > 0 && getAllReportData[0]?.outlook_report?.title
                    :
                    // getAllReportData.length > 0 && getAllReportData[0]?.outlook_report?.is_topic == false ?
                    getAllReportData.length > 0 && getAllReportData[0]?.outlook_report?.title
                        // :
                        // ''

                    }
                </h5>
                <p style={{
                    fontSize: '15px',
                    margin: '0px',
                    fontWeight: '400',
                    marginBottom: '5px'
                }}>
                    {getAllReportData.length > 0 && getAllReportData[0]?.outlook_report?.description}
                </p>

                <h6 style={{
                    margin: '0px',
                    fontSize: '15px',
                    fontWeight: '600',
                    marginBottom: '5px'
                }}>{getAllReportData.length > 0 && getAllReportData[0]?.outlook_report?.channel ?


                    `Channel : ${getAllReportData[0]?.outlook_report?.channel.replace(/{|}/g, '')}`
                :
                getAllReportData.length > 0 && getAllReportData[0]?.outlook_report?.channel &&
                `Channel : ${getAllReportData[0]?.outlook_report?.channel.replace(/{|}/g, '')}`
                
                }</h6>
                <p style={{
                    margin: '0px',
                    fontSize: '16px',
                    color: '#7e7eef',
                    fontWeight: '500',
                    marginBottom: '5px',
                    textAlign: 'start',
                    wordBreak:'break-all'
                }}>{getAllReportData.length > 0 && getAllReportData[0]?.outlook_report?.hyperlink &&
                    `${getAllReportData[0]?.outlook_report?.hyperlink
                    }`
                    }</p>
                <p style={{
                    margin: '0px',
                    fontSize: '16px',
                    // color: '#7e7eef',
                    fontWeight: '500',
                    marginBottom: '5px'
                }}>{getAllReportData.length > 0 && getAllReportData[0]?.outlook_report?.query &&
                    `Query Used: ${getAllReportData[0]?.outlook_report?.query}`
                    }</p>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom:'25px'
                }}>
                    <p style={{
                        fontSize: '15px',
                        margin: '0px',
                        fontWeight: '400'
                    }}>{getAllReportData.length > 0 && getAllReportData[0]?.outlook_report?.start_date &&
                        `${getAllReportData[0]?.outlook_report?.is_topic ? 'Topic' : 'Chatter'} Created on ${getAllReportData[0]?.outlook_report?.start_date}`}</p>
                    <div style={{
                        width: '265px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: "center"
                    }}>
                        <button style={{
                            width: '110px',
                            height: '39.66px',
                            border: '1px solid rgb(53, 60, 247)',
                            background: "transparent",
                            fontSize: '15px',
                            fontWeight: '500',
                            color: 'rgb(53, 60, 247)',
                            borderRadius: '5px'
                        }}

                            onClick={() => setShowSplitScreen(false)}
                        >Cancel</button>
                        {
                            getAllReportData?.length > 0 &&

                            <button style={{
                                width: '135px',
                                background: 'rgb(53, 60, 247)',
                                border: '1px solid rgb(53, 60, 247)',
                                borderRadius: '5px',
                                margin: '0px',
                                padding: '0px',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                padding: '9px',
                                fontSize: "15px"
                            }}
                                onClick={() => handleGeneratePdf(contentDivRef)}
                            >{
                                    download ?
                                        '...downloading' :
                                        <>Download <Download style={{

                                            fontSize: '20px'
                                        }}
                                        /></>
                                }
                            </button>
                        }
                    </div>
                </div>
            </div>
            {getAllReportData?.length > 0 && getAllReportData[0]?.render_string != null && getAllReportData[0]?.render_string != undefined  &&

                <div style={{
                    
                    // display:'none'
                }}>
                    <div ref={contentDivRef} id="element-to-convert" >
                        <DownloadFile
                            AnalysisFilter={getAllReportData?.length > 0 && JSON?.parse(getAllReportData[0]?.render_string)}
                            email_id={data?.email_id + ''}
                            created_at={data.creation_time}
                            is_topic={data.is_topic}
                            data={data}
                        />
                    </div>
                </div>
            }
        </SplitScreenWrapper>
    );
};

export default SplitScreenReports;
