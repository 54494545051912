import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './alertMessageSlice';
import AnalysisService from '../services/competitiveIntelligence.service';

export const createNewAnalysis = createAsyncThunk(
    'createNewAnalysis',
    async (inputData, thunkAPI) => {
      try {
        const brandUser = JSON.parse(localStorage.getItem('brandUser'));
        const data = await AnalysisService.createNewAnalysis(
          brandUser?.tenant_id,
        //   brandUser?.user_details.user_id,
          inputData
        );
        return { data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );

export const competitiveIntelligenceList = createAsyncThunk(
  'competitiveIntelligenceList',
  async (inputData, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await AnalysisService.competitiveIntelligenceList(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        // inputData
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

  