import React, { useState, useEffect } from 'react';
import {
  Preview,
  Wrapper,
  Text,
  QueryBuilderTitle_with_PreviewButton,
  KeywordWrapper,
  Label,
  SubLabel,
  ErrorMessage,
  LoadingWrapper,
} from '../TopicBuilder/MainContent/QueryBuilder/TopicBuilder.styled';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DateSlider from '../../Common/DateSlider/dateSlider';
import ConnectChannels from '../TopicBuilder/MainContent/ConnectedChannels/ConnectedChannels';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '../../Common/Select/Select';
import ChannelDropdown from '../ChatterTracker/MainComponent/ChatterTrackerBuilder/ShoppingChannels/ChannelDropdown';
import PopUp from '../../Common/PopUp/PopUp';
import Button from '../../Common/Button/Button';
import { formatDateFilter } from '../../utils';
import { createNewAnalysis } from '../../store/redux/slices/competitiveIntelligenceSlice';

const ToggleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: min-content;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  border: 1px solid #d9d9d9;

  & :not(:first-child) {
    border-left: 1px solid #e4e4e4;
    border-radius: 0px;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 30px;

  font-size: 15px;
  font-weight: 600;

  & input {
    width: 200px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(162, 162, 162, 0.24);
    ${'' /* text-align: center; */} 
    padding: 1px 8px;
    color: #636363;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
`;
const Option = styled.span`
  white-space: nowrap;
  cursor: pointer;
  color: #222;
  border-radius: 0px;
  ${(props) =>
    props.selected &&
    `color: #fff;
      background:#353CF7;
      border-radius: 4px !important;
      font-weight: 500;
  `};
  padding: ${(props) => props.padding};
`;

const CancelButton = styled.span`
  cursor: pointer;
  ${'' /* float: right; */}
  margin: 20px 20px 0px 0px;

  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
function CreateComparison() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTopicOne, setselectedTopicOne] = useState(null);
  const [selectedTopicTwo, setselectedTopicTwo] = useState(null);
  const [topicOptions, setTopicOptions] = useState([]);
  const [channelType, setChannelType] = useState('Topic');
  const [channel, setChannel] = useState(null);
  const [startDateOne, setStartDateOne] = useState('');
  const [endDateOne, setEndDateOne] = useState('');
  const [startDateTwo, setStartDateTwo] = useState('');
  const [endDateTwo, setEndDateTwo] = useState('');
  const [channelError, setChannelError] = useState('');
  const [showModal, setShowModal] = useState(false)
  const { activeTopicListData } = useSelector((state) => state.topicBuilder) || [];
  const {activeChatterListData}  = useSelector((state) => state.chatterTracker) || [];
  const handleChannelSelect = (selected) => {
    if (channel === selected) {
      setChannel(null); 
    } else {
      setChannel(selected); 
    }
    let list = activeTopicListData.filter((topic) => topic.channel === selected)
    const opt = list?.map((li) =>{
      return li.topic_title
    }) 
    if(opt.length > 0){
      setTopicOptions(opt)
    }else{
      setShowModal(true)
    }
  };


  const handleButtonClick = (event) => {
    const data = {
      job_id_1:selectedTopicOne.job_id,
      start_date_1: startDateOne,
      end_date_1: endDateOne,
      job_id_2: selectedTopicTwo.job_id,
      start_date_2: startDateTwo,
      end_date_2: endDateTwo
    }
    dispatch(createNewAnalysis(data)).then((res) => {
      navigate('/competitiveIntelligence/ci-' + res.payload.data.ci_id);
    });
  };

  const ToggleButton = ({
    options = [],
    onClick = () => {},
    padding = '5px 20px',
    option
  }) => {
    const [selectedOption, setSelectedOption] = useState(option);
    const handleSelectOption = (opt) => {
      onClick(opt);
      setSelectedOption(opt);
    };
    return (
      <ToggleButtonWrapper>
        {options?.map((op) => (
          <Option
            selected={selectedOption === op}
            onClick={() => handleSelectOption(op)}
            padding={padding}
            key={op}
          >
            {op}
          </Option>
        ))}
      </ToggleButtonWrapper>
    );
  };

 const handleChannelType = (type) =>{
  setChannelType(type)
 }

 const handleTopicOneChange = (opt) =>{
  const selected = activeTopicListData.find((option) => option.topic_title === opt)
  setselectedTopicOne(selected)
  // if(selectedTopicOne){
    setStartDateOne(formatDateFilter(selected?.start_date))
    setEndDateOne(formatDateFilter(selected?.end_date))
  // }
 }

 const handleTopicTwoChange = (opt) =>{
  const selected = activeTopicListData.find((option) => option.topic_title === opt)
  setselectedTopicTwo(selected)
  // if(selectedTopicTwo){
    setStartDateTwo(formatDateFilter(selected?.start_date))
    setEndDateTwo(formatDateFilter(selected?.end_date))
  // }
 }

 const dateHandler = (e, count) =>{
  const { name, value } = e.target;
    if (name === 'firstPostDate') {
      count == 1 ? setStartDateOne(value) : setStartDateTwo(value);
    } else if (name === 'lastPostDate') {
      count == 1 ? setEndDateOne(value) : setEndDateTwo(value);
    }
 }
 useEffect(() => {
  console.log('startDateOne updated:', startDateOne);
  console.log('startDateOne updated:', startDateTwo);
}, [startDateOne, startDateTwo]);

 const onDateSliderChange = (e) =>{
  if(e.topicCount == 1){
    setStartDateOne(formatDateFilter(e.startDate))
    setEndDateOne(formatDateFilter(e.endDate))
  }else{
    setStartDateTwo(formatDateFilter(e.startDate))
    setEndDateTwo(formatDateFilter(e.endDate))
  }
 }
  return (
    <Wrapper>
      {
        showModal && (
          <PopUp onClose={() => setShowModal(false)}>
            <div>
              <p style={{color:'red'}}>No Topic Available</p>
                  <div >
                    <p>It seems there's no topic currently available.
                    Would you like to select a different channel or go back to the home screen?</p> 
                  </div>
              <br />
              <CancelButton onClick={() => {
                navigate('/competitiveIntelligence/home')
                setShowModal(false)
                setChannel(null)
                }}>
                Back to home
              </CancelButton>
              <Button onClick={() => {
                setShowModal(false)
                setChannel(null)
                }}
               value={'Select Different Channel'} 
               style={{height: 40, width: 200}}
              />
            </div>
          </PopUp>
        ) 
      }
      <Text>
      Effortlessly compare data and generate comprehensive competitive 
      intelligence reports, delivering actionable insights at your fingertips.
      </Text>
      <QueryBuilderTitle_with_PreviewButton>
      <ToggleButton
        options={['Topic', 'Chatter']}
        onClick={handleChannelType}
        option={'Topic'}
      />
        <Preview onClick={handleButtonClick}>Compare</Preview>
      </QueryBuilderTitle_with_PreviewButton>
          <KeywordWrapper>
            <Label>
              Channels <span>*</span>
            </Label>
            <br />
            <SubLabel>Select interested channel for topic comparison</SubLabel>
            <div style={{width:'40%'}}>
            {channelType === 'Topic' ? <ConnectChannels
              handleConnectChannelsToggle={handleChannelSelect}
              channel={channel}
            /> :
            <ChannelDropdown onOptionSelect={handleChannelSelect} />
            }
            </div>
            {channelError && <ErrorMessage>{channelError}</ErrorMessage>}
          </KeywordWrapper>
          {
            channel !== null && showModal !== true &&
            <div>
              <SubLabel style={{marginTop: 40}}>Select topics to generate comprehensive competitive intelligence reports</SubLabel>
              <div>
                <p>Topic A</p>
                <div style={{borderRadius: 6, border: '1px solid, #A2A2A2', padding: 30}}>
                <div style={{width: '40%'}}>
                <Select
                  onChange={handleTopicOneChange}
                  option={topicOptions || []} 
                  placeholder='Select preferred topic'
                  style={{
                    placeholderColor: '#636363',
                    borderColor: '#d9d9d9'
                  }}
                  checkbox={false}
                />
                </div>
                {
                  selectedTopicOne !== null &&
                  <div>
                  <div style={{marginTop: 20, marginBottom: 20, borderRadius: 6, border: '1px solid #d9d9d9'}}>
                    <DateSlider 
                      startDate={selectedTopicOne?.start_date}
                      endDate={selectedTopicOne?.end_date}
                      // progress={{ start: 20, end: 60 }}
                      onChange={onDateSliderChange}
                      topicCount={1}
                    />
                  </div>
                  <DateWrapper>
                  <div>
                    <div>Start Date:</div>
                    <input
                      type='date'
                      name='firstPostDate'
                      value={startDateOne}
                      onChange={(e) => dateHandler(e, 1)}
                    />
                  </div>
                  <div>
                    <div>End Date:</div>
                    <input
                      type='date'
                      name='lastPostDate'
                      value={endDateOne}
                      onChange={(e) => dateHandler(e, 1)}
                    />
                  </div>
                </DateWrapper>
                </div>
                }
                 
                </div>
              </div>
              <div style={{marginTop: 30}}>
                <p>Topic B</p>
                <div style={{borderRadius: 6, border: '1px solid #A2A2A2', padding: 30}}>
                  <div style={{width: '40%'}}>
                    <Select
                      onChange={handleTopicTwoChange}
                      option={topicOptions || []} 
                      placeholder='Select preferred topic'
                      style={{
                        placeholderColor: '#636363',
                        borderColor: '#d9d9d9'
                      }}
                      checkbox={false}
                    />
                  </div>
                  { selectedTopicTwo !== null &&
                  <div>
                  <div style={{marginTop: 20, marginBottom: 20, borderRadius: 6, border: '1px solid #d9d9d9'}}>
                  <DateSlider 
                    startDate={selectedTopicTwo?.start_date}
                    endDate={selectedTopicTwo?.end_date }
                    // progress={{ start: 20, end: 60 }}
                    onChange={onDateSliderChange}
                    topicCount={2}
                  />
                </div>
                <DateWrapper>
                  <div>
                    <div>Start Date:</div>
                    <input
                      type='date'
                      name='firstPostDate'
                      value={startDateTwo}
                      onChange={(e) => dateHandler(e,2)}
                    />
                  </div>
                  <div>
                    <div>End Date:</div>
                    <input
                      type='date'
                      name='lastPostDate'
                      value={endDateTwo}
                      onChange={(e) => dateHandler(e,2)}
                      // min={startDate}
                      // max={initialEndDate}
                    />
                  </div>
                </DateWrapper>
                  </div>
                  }
                
                </div>
              </div>
            </div>
          }
    </Wrapper>
  );
}

export default CreateComparison;