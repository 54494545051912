// WordCloudComponent.js
import { colors } from '@mui/material';
import { scaleLinear } from 'd3-scale';
import { max } from 'moment/moment';
import React from 'react';
import Cloud from 'react-d3-cloud';

const CustomWordCloud = ({ words, selectedSentiments, selectedEmotions, sentimentData }) => {

  const minFontSize = 10;
  const maxFontSize = 100;

  const fontSizeScale = scaleLinear()
    .domain([
      Math.min(...words.map((word) => word.value)),
      Math.max(...words.map((word) => word.value)),
    ])
    .range([minFontSize, maxFontSize]);

  const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
    fontSizes: [minFontSize, maxFontSize],
    font: 'Arial'
  };

  let maxValue = words[0]?.value;
  let minValue = words[words.length - 1]?.value;
  const diff = maxValue - minValue;

  // console.log("max",maxValue,"  min", minValue );
  const fontSizeMapper = word => word.value * 3;

  const fill = word => {

    // Assign color based on sentiment
    if (word.color === 'positive') {
      return '#73EBA4';
    } if (word.color === 'negative') {
      return '#FC6758'
    } if (word.color === 'neutral') {
      return 'rgb(245 188 44)'
    } if (word.color === 'joy') {
      return '#FE00D5'
    } if (word.color === 'surprise') {
      return '#B7DC68'
    } if (word.color === 'neutralEmotion') {
      return '#F5CC61'
    } if (word.color === 'anger') {
      return '#F74945'
    } if (word.color === 'disgusted') {
      return '#9071CE'
    } if (word.color === 'sadness') {
      return '#4B9AE9'
    } if (word.color === 'fear') {
      return '#FC9E58'
    }
  }

  const rotate = word => Math.floor(Math.random() * 2) * 90;
  // Define a color scale for the word cloud

  // const options = {
  //   font: 'Arial',
  //   rotations: 2,
  //   rotationAngles: [-90, 0],
  // };

  return (
    <div style={{ width: '100%', height: '55vh', }}>
      <Cloud
        data={words}
        rotate={rotate}
        width={300}
        height={100}
        padding={0}
        font={options.font}
        fill={fill}
        // fontSize={(word) => Math.log2(word.value) * 2}
        fontSize={(word) => Math.sqrt(word.value) * 2}
        // fontSize={options.fontSizes}
        // fontSize={fontSizeMapper}
      />

      {/* <Resizable
          // defaultSize={{
          //   width: 300,
          //   height: 100,
          // }}
          style={resizeStyle}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <ReactWordcloud options={options} words={words} />
          </div>
        </Resizable> */}
        
    </div>
  );
};

export default CustomWordCloud;
