import react, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GraphDropdown from '../../Common/GraphDropDown/GraphDropdown';
import RatingDonutChart from '../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../Common/SentimentCharts/SentimentCharts';
import GenderBarGraph from '../../Pages/ChatterTracker/MainComponent/ChatterPreview/OverViewTab/GenderGraph';
import FunnelGraph from '../../Common/FunnelGraph/FunnelGraph';
import BarGraph from '../../Common/BarGraph/BarGraph';
import VerticalBarGraph from '../../Common/BarGraph/VarticalBargraph';
import GraphHOC from '../../Common/GraphHOC/GraphHOC';

const Wrapper = styled.div`
  width: 50%;
  height: 380px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  justify-content: space-between;

  &>span: first-child {
    /* display: flex; */
    /* margin: 20px 0px 0px 20px; */
    color: #636363;
    font-size: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
  }
`;

// const LineWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding-right: 1em;
// `;

// const Span = styled.div`
//   color: rgba(0, 0, 0, 0.87);
//   font-family: Open Sans;
//   font-size: 11px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: normal;
//   align-self: flex-end;
//   text-align: right;
//   justify-content: space-between;
//   margin-right: 20px;
//   margin-bottom: 5px;
// `;

const FunnelGraphContaining = styled.div`
  display: flex;
  flex: 1 1 0%;
  width: 100%;
  margin: 0px;
  -webkit-box-align: center;
  align-items: center;
`;

const GenderDemoGraphics = ({ data, startDate, endDate,filterOption, view, options,hideDropdown }) => {
  const [selectedGraph, setSelectedGraph] = useState('Bar Graph');
  
  const tempData = [
    {
      name: 'Female',
      value: data?.gender_demographics?.female_percentage,
      fill: '#EAAABD',
    },
    {
      name: 'Male',
      value: data?.gender_demographics?.male_percentage,
      fill: '#6640B8',
    },
    {
      name: 'Unknown',
      value: data?.gender_demographics?.unknown_percentage,
      fill: '#D2D2D4',
    },
  ];


  // const maleRating = data?.gender_demographics?.male_percentage;
  // const femaleRating = data?.gender_demographics?.female_percentage;
  // const unknown = data?.gender_demographics?.unknown_percentage;

  // const handleGraphSelect = (option) => {
  //   // Your logic to handle the selected graph in the parent component
  //   setSelectedGraph(option);
  // };
  const renderGenderDemogrphicsGraph = {
      'Donut Chart':
        ( <DonutChart data={tempData} type='percentage' />),
      'Bar Graph':
        view === 'vertical'?  (
            <div style={{ margin: '40px 0px 0px 0px' }}>
              <VerticalBarGraph
                data={tempData}
                type={'%'}
                borderRadius={'0px 12px 12px 0px'}
                barHeight={'45px'}
                maxWidth={'80%'}
              />
            </div>
          ) : (
           <FunnelGraphContaining>
              <BarGraph
                data={tempData}
                type={'%'}
                /* borderRadius={'0px 12px 12px 0px'} */
                barHeight={'32px'}
                maxWidth={'80%'}
              />
              </FunnelGraphContaining>
          ),
        
      'Pie Chart':
         (<RatingDonutChart data={tempData} type='percentage' />),
      // Add more cases for other graph options
      'Funnel Chart':
         (
          <FunnelGraphContaining>
            <FunnelGraph data={tempData} type={'%'} />
          </FunnelGraphContaining>
        ),
    }

  return (
    <Wrapper>
      <GraphHOC
        title={'Gender Demographics'}
        options={options}
        renderScreen={renderGenderDemogrphicsGraph}
        option={filterOption}
        startDate={startDate}
        endDate={endDate}
        hideDropdown={hideDropdown}
      />
      {/* <LineWrapper>
        <p style={{color:'black'}}>Gender Demographics</p>
        <GraphDropdown
          options={['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart']}
          onSelectGraph={handleGraphSelect}
          defaultOption='Bar Graph'
        />
      </LineWrapper>
      <div
        style={{
          display: 'flex',
          height: '79%',
          flexDirection: 'column',
          gap: '2em',
          paddingLeft: '1em',
          textAlign: 'center',
          flex: 1,
        }}
      >
        {renderGenderDemogrphicsGraph('demographics')}
      </div>
      <Span>
        This data is observed from {startDate} to {endDate}
      </Span> */}
    </Wrapper>
  );
};

export default GenderDemoGraphics;
