import './App.css';
import AlertPopUp from './components/AlertPopUp/AlertPopUp';
import CustomRoutes from './routes';
import { useSelector } from 'react-redux';
import * as Sentry from "@sentry/react";


// console.log(process.env.REACT_APP_ENV);


Sentry.init({
  dsn: "https://abbf18715ea141bea6d48bb963bd6126@o4507927946395648.ingest.us.sentry.io/4507961147588608",
  integrations: [
    // new Integrations.BrowserTracing({
    //     routingInstrumentation: Sentry.reactRouterV6Instrumentation(), // For React Router v6
    //   }),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/trekmonk\.brandastra\.ai/ ],// Regex for the additional domain],
  tracePropagationTargets: [
    "localhost", 
    /^https:\/\/trekmonk\.brandastra\.ai/,
    /^https:\/\/qa\.brandastra\.ai\/abc\/login/ // Regex for the new domain
  ],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const alertPopUp = useSelector((state) => state.message);

  return (
    <div className='App'>
      {alertPopUp.message && <AlertPopUp />}
      <CustomRoutes />
    </div>
  );
}

export default App;
