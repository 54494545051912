import React, { useState } from 'react';
import AmazonImg from '../../../../../assests/images/Amazon_img.png';
import AllExpressImg from '../../../../../assests/images/all_express.png';
import BestBuyImg from '../../../../../assests/images/best_buy.png';
import EbayImg from '../../../../../assests/images/ebay.png';
import EtsyImg from '../../../../../assests/images/etsy.png';
import FlipkartImg from '../../../../../assests/images/flipkart.png';
import TargetImg from '../../../../../assests/images/target.png';
import WalmartImg from '../../../../../assests/images/walmart.png';
import ZapposImg from '../../../../../assests/images/zappos.png';
import styled from 'styled-components';
import { DownArrowIcon } from '../../../../../Common/SvgIcon/CommonSvgIcons';

const CustomSelect = styled.div`
  width: 100%;
  height: 54px;
  border-radius: ${(props) => (props.isOpen ? '15px 15px 0px 0px' : '15px')};
  border: 1px solid #a2a2a2;
`;

const DropdownHeader = styled.div`
  cursor: pointer;
  //border: 1px solid #ccc;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    margin: 8px 0px 0px 5px;
    color: #a1a1a1;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  p {
    margin: 8px 0px 0px 5px;
    color: black;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600; 
  }
`;

const DropdownOptions = styled.ul`
  height: 200px;
  overflow-y: auto;
  padding: 0;
  margin: -5px 0px 80px -1px;
  background-color: white;
  border-radius: 0px;
  border: 1px solid #A2A2A2;
  border-top:none;
  overflow-y:scroll;
  display: none;
  
  ${(props) =>
    props.isOpen &&
    `
    display: block;
  `}
  `;

const DropdownOption = styled.li`
  cursor: pointer;
  width:90%;
  padding: 8px;
  height: 35px;
  display: flex;
  align-items: left;
  margin: 10px 0px 0px 20px;
  border-radius: 15px;
  border: ${(props) =>
    props.isSelected ? '1px solid #377DFF' : '1px solid #d9d9d9'};
  background: ${(props) =>
    props.isSelected ? 'rgba(55, 125, 255, 0.14)' : 'inherit'};

  img {
    margin-left: 10px;
    margin-right: 15px;
  }

  span {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 8px;
  }
`;

const Arrow = styled.span`
  margin-left: 0px;
`;
function ChannelDropdown({ onOptionSelect }) {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptionImage,setSelectedOptionImage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const options = [
    { value: 'Amazon', label: 'Amazon', imageSrc: AmazonImg },
    { value: 'Flipkart', label: 'Flipkart', imageSrc: FlipkartImg },
    { value: 'EBay', label: 'EBay', imageSrc: EbayImg },
    { value: 'Walmart', label: 'Walmart', imageSrc: WalmartImg },
    { value: 'Etsy', label: 'Etsy', imageSrc: EtsyImg },
    { value: 'Best buy', label: 'Best buy', imageSrc: BestBuyImg },
    { value: 'Target', label: 'Target', imageSrc: TargetImg },
    { value: 'AliExpress', label: 'AliExpress', imageSrc: AllExpressImg },
    { value: 'Zappos', label: 'Zappos', imageSrc: ZapposImg },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onOptionSelect(option.value);
    setSelectedOption(option.value)
    setSelectedOptionImage(option.imageSrc)
    setIsOpen(false);
    setShowPopup(true);
  };

  return (
    <CustomSelect>
      <DropdownHeader isOpen={isOpen} onClick={toggleDropdown}>
        {selectedOption ? (
         /*  <p><img src={selectedOptionImage} alt='logo' height='35px'/>{selectedOption} </p> */
         <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={selectedOptionImage} alt='logo' height='35px' />
      <p>{selectedOption}</p>
    </div>
        ) : (
          <span>Select consumer platform</span>
        )}
        <Arrow>{(isOpen || selectedOption) ? '' : DownArrowIcon}</Arrow>
      </DropdownHeader>
      <DropdownOptions isOpen={isOpen}>
        {options.map((option) => (
          <DropdownOption
            isSelected={option.value === selectedOption}
            key={option.value}
            onClick={() => handleOptionClick(option)}
          >
            <img src={option.imageSrc} alt={option.label} width='50px' />
            <span>{option.label}</span>
          </DropdownOption>
        ))}
      </DropdownOptions>
    </CustomSelect>
  );
}

export default ChannelDropdown;
