import React, { useState } from 'react';
import styled from 'styled-components';
import {
    AmazonIconSmall,
    FlipkartLogo,
    StarIcon,
} from '../../../Common/SvgIcon/CommonSvgIcons';
// import PieChartComponent from "./SentimentCharts";
import DonutChart from '../../../Common/SentimentCharts/SentimentCharts';
import GenderBarGraph from './GenderGraph';
import AverageRatingGraph from '../../../Common/LineGraph/LineGraph';
import RatingDonutChart from '../../../Common/RatingDonutChart/DonutChart';
import GraphDropdown from '../../../Common/GraphDropDown/GraphDropdown';
import Lottie from 'lottie-react';
import FunnelGraph from '../../../Common/FunnelGraph/FunnelGraph';
import VerifiedNonVerifiedUserGraph from '../../../Common/VerifiedNonVerifiedUserGraph/VerifiedNonVerifiedUserGraph';
import TrendsInUserActivity from '../../../Common/TendsInUserActivity/TrendsInUser_activity';
import GenderDemoGraphics from '../../../components/GenderDemoGraphics/GenderDemoGraphics';
import GenderRating from '../../../components/GenderRating/GenderRating';
import GraphHOC from '../../../Common/GraphHOC/GraphHOC';
import BarGraph from '../../../Common/BarGraph/BarGraph';
import VerticalBarGraph from '../../../Common/BarGraph/VarticalBargraph';
import Loader from '../../../assests/animation/Loader2.json'
import {
    Wrapper,
    FlexWrapper1,
    ContentWrapper1,
    TextWrapper,
    IconWrapper,
    ContentWrapper2,
    GraphContainer,
    Span, ContentWrapperFlipkart,
    FlexWrapper2, ChildContainerGenderBasedAvg,


    ChildContainer,
    Title,
    Div,
    GraphWrapper,

} from './OverView.styled.js'

function OverViewTab({
    plan,
    selectedReport,
    filterOption,
    view,
    hideDropdown
}) {
   /* const [timeSpan, setTimeSpan] = useState('day'); */
    const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

    let planSpecificData;
    if (selectedReport) {
        if (plan === 'BrandAstraPro') {
            planSpecificData = selectedReport?.outlook_report?.data?.overview_tab;
        } else if (plan === 'BrandEssence') {
            planSpecificData = selectedReport?.outlook_report?.BrandAstraEssence?.overview_tab;
        } else if (plan === 'BrandAstraElite') {
            planSpecificData = selectedReport?.outlook_report?.BrandAstraElite;
        } else {
            planSpecificData = selectedReport?.outlook_report?.data?.overview_tab;

        }
    }

    const averageRating =
        planSpecificData?.overall_customer_satisfaction?.average_rating ||
        0;

    const percentageValue = planSpecificData?.gender_based_avg_rating;

    const genderBasedRatingData = [
        {
            name: 'Male ',
            value: parseFloat(percentageValue?.male_percentage),
            fill: '#6640B8',
        },
        {
            name: 'Female',
            value: parseFloat(percentageValue?.female_percentage),
            fill: '#EAAABD',
        },
        {
            name: 'Unknown',
            value: parseFloat(percentageValue?.unknown_percentage),
            fill: '#D2D2D4',
        },
    ];

    const verifiedAndNonVerifiedData = planSpecificData?.verified_non_verified_users;

    const verifiedPurchaseRating = planSpecificData?.overall_customer_satisfaction?.verified_purchase;

    const nonVerifiedPurchaseRating = planSpecificData?.overall_customer_satisfaction?.non_verified_purchase;

    const renderGenderBasedRatingGraph = {
        'Bar Graph': view === 'vertical' ? (
            <VerticalBarGraph
                data={genderBasedRatingData}
                type={StarIcon}
                starRating={true}
            />
        ) : (
            <BarGraph
                data={genderBasedRatingData}
                type={StarIcon}
                starRating={true}
                // borderRadius={'0px 12px 12px 0px'}
                barHeight={'32px'}
                maxWidth={'80%'}
            />
        ),
        'Pie Chart': <RatingDonutChart data={genderBasedRatingData} />,
        'Donut Chart': <DonutChart data={genderBasedRatingData} />,
        'Funnel Chart': <FunnelGraph data={genderBasedRatingData} />,
    };

    const genderBasedAverageRatingGraph =
        renderGenderBasedRatingGraph[filterOption];

    const userRatingValue = planSpecificData?.customer_ratings;
    const userRating = [
        {
            name: '5 star ',
            value: parseFloat(userRatingValue?.five_star_ratings),
            fill: '#676DFF',
        },
        {
            name: '4 star',
            value: parseFloat(userRatingValue?.four_star_ratings),
            fill: '#898DFB',
        },
        {
            name: '3 star',
            value: parseFloat(userRatingValue?.three_star_ratings),
            fill: '#ACAFFC',
        },
        {
            name: '2 star',
            value: parseFloat(userRatingValue?.two_star_ratings),
            fill: '#C5C6F3',
        },
        {
            name: '1 star',
            value: parseFloat(userRatingValue?.one_star_ratings),
            fill: '#D5D5DC',
        },
    ];

    const renderScreen = {
        'Bar Graph':
            view === 'vertical' ? (
                <VerticalBarGraph data={userRating} type={'%'} />
            ) : (
                <BarGraph
                    data={userRating}
                    type={'%'}
                    borderRadius={'0px 0px 0px 0px'}
                    barHeight={'30px'}
                />
            ),
        'Pie Chart': <RatingDonutChart data={userRating} type='percentage' />,
        'Donut Chart': <DonutChart data={userRating} type='percentage' />,
        'Funnel Chart': <FunnelGraph data={userRating} type={'%'} />,
    };
    const customerSatisfaction = [
        {
            name: 'Average Rating ',
            value: averageRating,
            fill: '#6640B8',
        },
        {
            name: 'Verified Purchase',
            value: verifiedPurchaseRating,
            fill: '#5FE495',
        },
        {
            name: 'Non Verified Purchase',
            value: nonVerifiedPurchaseRating,
            fill: '#353cf7'
        },
    ];

    const renderCustomerSatisfaction = {
        'Bar Graph':
            view === 'vertical' ? (
                <VerticalBarGraph
                    data={customerSatisfaction}
                    type={StarIcon}
                    starRating={true}
                />
            ) : (
                <BarGraph
                    data={customerSatisfaction}
                    type={StarIcon}
                    starRating={true}
                    /* borderRadius={'0px 12px 12px 0px'} */
                    barHeight={'32px'}
                    maxWidth={'80%'}
                />
            ),
        'Pie Chart': <RatingDonutChart data={customerSatisfaction} />,
        'Donut Chart': <DonutChart data={customerSatisfaction} align="left" />,
        'Funnel Chart': <FunnelGraph data={customerSatisfaction} />,
    };

    const customerRatingGraph = renderScreen[filterOption];

    return (
        <>
            {
                (
                    <Wrapper>
                        <FlexWrapper1>
                            <ContentWrapper1>
                                <p>Overall Reviews</p>
                                <TextWrapper>
                                    {selectedReport?.outlook_report?.channel === 'amazon' ? (
                                        <IconWrapper>{AmazonIconSmall}</IconWrapper>
                                    ) : (
                                        <IconWrapper>{FlipkartLogo}</IconWrapper>
                                    )}
                                    {/* <IconWrapper>{AmazonIconSmall}</IconWrapper> */}
                                    <span>
                                        {planSpecificData?.total_count}{' '}
                                        Relevant reviews were observed from{' '}
                                        {selectedReport?.outlook_report?.start_date} to{' '}
                                        {selectedReport?.outlook_report?.end_date}.
                                    </span>
                                </TextWrapper>
                            </ContentWrapper1>
                            {/* <CustomerRating
                                data={planSpecificData}
                                startDate={chatterData?.start_date}
                                endDate={chatterData?.end_date}
                                />
                            */}

                            {
                                selectedReport?.outlook_report?.channel == 'flipkart' ?

                                    <ContentWrapper2>
                                        <p>Overall Customer Satisfaction</p>
                                        <GraphContainer>
                                            <AverageRatingGraph
                                                rating={averageRating}
                                                backgroundColor='#6640B8'
                                                title='Average Rating'
                                            />

                                        </GraphContainer>

                                        <Span>
                                            This data is observed from{' '}
                                            {selectedReport?.outlook_report?.start_date} to{' '}
                                            {selectedReport?.outlook_report?.end_date}
                                        </Span>
                                    </ContentWrapper2>
                                    :

                                    <ContentWrapper2>
                                        {/* <p>Overall Customer Satisfaction</p> */}
                                        <GraphHOC
                                            title={'Overall Customer Satisfaction'}
                                            options={options}
                                            renderScreen={renderCustomerSatisfaction}
                                            option={filterOption}
                                            startDate={selectedReport?.outlook_report?.start_date
                                            }
                                            endDate={selectedReport?.outlook_report?.end_date
                                            }
                                            hideDropdown={hideDropdown}
                                        />
                                    </ContentWrapper2>
                            }
                        </FlexWrapper1>
                        {
                            selectedReport?.outlook_report?.channel == 'flipkart' ?
                                <FlexWrapper2>
                                    {/* {filterOption ? (
                              <ContentWrapperFlipkart>
                                <Title>Customer Rating</Title>
                                <div style={{marginTop: 20}}>
                                  <GraphDropdown
                                    options={options}
                                    onSelectGraph={handleGraphSelect}
                                    defaultOption='Bar Graph'
                                  />
                                </div>
                                <Div>{customerRatingGraph}</Div>
                              </ContentWrapperFlipkart>
                            ) : ( */}
                                    <ContentWrapperFlipkart>
                                        <GraphHOC
                                            title={'Customer Rating'}
                                            options={options}
                                            renderScreen={renderScreen}
                                            option={filterOption}
                                            startDate={selectedReport?.outlook_report?.start_date
                                            }
                                            endDate={
                                                selectedReport?.outlook_report?.end_date
                                            }
                                            hideDropdown={hideDropdown}
                                        />
                                    </ContentWrapperFlipkart>
                                    {/* )} */}
                                </FlexWrapper2>
                                :
                                <FlexWrapper2>
                                    {
                                        filterOption ? (
                                            <ContentWrapper1>
                                                <Title>Customer Rating</Title>
                                                <Div>{customerRatingGraph}</Div>
                                            </ContentWrapper1>
                                        ) :
                                            (
                                                <ContentWrapper1>
                                                    <GraphHOC
                                                        title={'Customer Rating'}
                                                        options={options}
                                                        renderScreen={renderScreen}
                                                        startDate={selectedReport?.outlook_report?.start_date}
                                                        endDate={selectedReport?.outlook_report?.end_date}
                                                        hideDropdown={hideDropdown}
                                                    />
                                                </ContentWrapper1>
                                            )}
                                    {selectedReport?.outlook_report?.channel != 'flipkart' && <VerifiedNonVerifiedUserGraph data={verifiedAndNonVerifiedData} />
                                    }
                                </FlexWrapper2>
                        }
                        <FlexWrapper2>
                            <GenderDemoGraphics
                                options={options}
                                data={planSpecificData}
                                startDate={selectedReport?.outlook_report?.start_date}
                                endDate={selectedReport?.outlook_report?.end_date}
                                filterOption={filterOption}
                                view={view}
                                hideDropdown={hideDropdown}
                            />
                            {filterOption ? (
                                <ChildContainer>
                                    <Title>Gender Based Average Rating</Title>
                                    <Div>{genderBasedAverageRatingGraph}</Div>
                                </ChildContainer>
                            ) : (
                                <ChildContainerGenderBasedAvg>
                                    <GraphHOC
                                        title={'Gender Based Average Rating'}
                                        options={options}
                                        renderScreen={renderGenderBasedRatingGraph}
                                        startDate={selectedReport?.outlook_report?.start_date}
                                        endDate={selectedReport?.outlook_report?.end_date}
                                        hideDropdown={hideDropdown}
                                    />
                                </ChildContainerGenderBasedAvg>
                            )}
                        </FlexWrapper2>
                        <GenderRating
                            hideDropdown={hideDropdown}
                            data={planSpecificData}
                            startDate={selectedReport?.outlook_report?.start_date}
                            endDate={selectedReport?.outlook_report?.end_date}
                        />
                        <GraphWrapper>
                            <TrendsInUserActivity
                                data1={planSpecificData}
                                title={'Trends In User Activity'}
                                hideDropdown={hideDropdown}
                                startDate={selectedReport?.outlook_report?.start_date}
                                endDate={selectedReport?.outlook_report?.end_date}
                            />
                        </GraphWrapper>
                    </Wrapper>
                )
            }
        </>

    );
}

export default OverViewTab;
