import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import tenantAuthReducer from './slices/tenantAuthSlice';
import tenantSettingAuthReducer from './slices/tenantSettingSlice';
import executivePortalReducer from './slices/executivePortalSlice';
import executivePortalTenantManagementReducer from './slices/executivePortalTenantManagementSlice';
import chatterTrackerReducer from './slices/chatterSlice';
import alertMessageReducer from './slices/alertMessageSlice';
import previewReducer from './slices/previewDataSlice';
import topicReducer from './slices/topicSlice';
import sentinelHubReducer from './slices/sentinelHubSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  tenantAuth: tenantAuthReducer,
  tenantSetting: tenantSettingAuthReducer,
  message: alertMessageReducer,
  executivePortal: executivePortalReducer,
  executivePortalTenantManagement: executivePortalTenantManagementReducer,
  chatterTracker: chatterTrackerReducer,
  preview: previewReducer,
  topicBuilder: topicReducer,
  sentinelHub: sentinelHubReducer,
});

export default rootReducer;
