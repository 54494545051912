import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: left;
  //position: relative;
  width: 100%;

  & > * {
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
  }
`;

const TextDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  & > * {
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
  }

  & > span {
    margin-left: -40px;
    color: #636363;
  }
`;

const TextField = styled.input`
  box-sizing: border-box;
  padding: 14px 18px;
  width: 100%;

  background: #ffffff;
  border: 1px solid ${({error})=>error? '#d74748': '#a2a2a2'};
  border-radius: 15px;
  outline: none;
  margin-top: 6px;
`;

const InputTitle = styled.label`
  padding: 8px;
  width: 100%;
`;

const ErrorMessage = styled.div`
  padding: 2px 10px;

  color: #d74748;
  font-family: Montserrat;
  font-size: 14px; 
  font-weight: 500;
  line-height: normal;
`;

const Input = ({
  label,
  require,
  type,
  name,
  placeholder,
  value,
  onChange,
  customStyle,
  characterLimit,
  maxLength,
  limit,
  readOnly,
  error = '',
}) => {
  const [remainingCharacters, setRemainingCharacters] =
    useState(characterLimit);

  useEffect(() => {
    if (value) {
      setRemainingCharacters(characterLimit - value.length);
    }
  }, [value, characterLimit]);

  return (
    <Wrapper>
      {label && (
        <>
          <InputTitle>
            {label}
            {require && <span style={{ color: '#FC6758' }}> *</span>}
          </InputTitle>
          <br />
        </>
      )}
      <TextDiv>
        <TextField
          type={type || 'text'}
          name={name || ''}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={customStyle}
          maxLength={maxLength}
          limit={limit}
          error={error}
          readOnly={readOnly}
        />
        {value && characterLimit ? (
          <span>{remainingCharacters}</span>
        ) : (
          <span>{limit}</span>
        )}
      </TextDiv>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};

export default Input;
