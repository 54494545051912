/**
 * @function isValidPhoneNumber
 * @description isValidPhoneNumber function will check the phone number value is valid or not.
 * @param {string} value
 * @returns boolean - true, if phone number value matches the condition
 */
export const isValidPhoneNumber = (value) => {
  return !!value.match(/^[6789]\d{9}$/);
};

/**
 *
 * @function isEmailValid
 * @param {string} userEmail
 * @returns true/false
 *
 */
export const isEmailValid = (userEmail) => {
  let validEmail = false;
  const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //Email validation using regex
  if (emailRegEx.test(userEmail)) {
    validEmail = true;
  }

  return validEmail;
};

/**
 *
 * @function isPasswordValid
 * @param {string} password
 * @returns true/false
 *
 */

export const isPasswordValid = (password) => {
  // In this example, the password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.
  const passwordRegEx =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&!])[A-Za-z\d@#$%^&!]{8,}$/;

  // Password validation using regex
  return passwordRegEx.test(password);
};

export const isUsernameValid = (username) => {
  // In this example, usernames must start with a letter, can contain letters, numbers, underscores, and hyphens, and must be 3 to 16 characters long
  // const usernameRegEx = /^[a-zA-Z][a-zA-Z0-9_-]{2,15}$/;

  const usernameRegEx = /^[a-zA-Z][a-zA-Z0-9_-]{7,15}$/;
  // Username validation using regex
  return usernameRegEx.test(username);
};
export const isFirstNameValid = (firstName) => {
  // In this example, first names can only contain letters and must be 2 to 30 characters long
  const firstNameRegEx = /^[A-Za-z]{2,30}$/;
  return firstNameRegEx.test(firstName);
};

export const isLastNameValid = (lastName) => {
  // In this example, last names can only contain letters and must be 2 to 30 characters long
  const lastNameRegEx = /^[A-Za-z]{2,30}$/;
  return lastNameRegEx.test(lastName);
};

/**
 *
 * @function isTokenValid
 * @param {string} token
 * @description isTokenValid function check the validity of a token with the current time and expiration time from JWT token
 * @returns true/false
 *
 */
export const isTokenValid = (token) => {
  if (!token) {
    return false;
  }

  try {
    // Split the token into its three parts
    const [header, payload, signature] = token.split('.');

    // Decode the payload using base64 decoding
    const decodedPayload = JSON.parse(atob(payload));

    // Extract the expiration timestamp from the payload
    const expirationTimestamp = decodedPayload.exp;

    // Check if the token is expired
    const now = Math.floor(Date.now() / 1000); // Current time in seconds
    return expirationTimestamp >= now;
  } catch (err) {
    console.error('Invalid token:', err);
    return false;
  }
};

/**
 *
 * @function getDate
 * @param {string} date
 * @description gets epoch time as data and returns day, year , month.
 * @returns object
 *
 */
export const getDate = (date) => {
  const newDate = new Date(date);
  if (newDate && !isNaN(newDate)) {
    // Step 2: Extract day, month, and year components
    const day = newDate.getDate(); // Get the day (1-31)
    const month = newDate.getMonth() + 1; // Get the month (0-11), add 1 to make it 1-12
    const year = newDate.getFullYear(); // Get the full year (e.g., 2024)

    // Step 3: Format into the desired date string format
    return `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;
  } else {
    return date.toString();
    // throw 'Invalid date supplied to getDate function';
  }
};

export const formatDate = (date = '') => {
  try {
    let dateString = date.split('/');
    return `${dateString[2]}/${dateString[1]}/${dateString[0]}`;
  } catch (err) {
    console.log('hide');
    return '';
  }
};

export const formatDateFilter = (date = '') => {
  try {
    let dateString = date.split('/');
    return `${dateString[2]}-${dateString[1]}-${dateString[0]}`;
  } catch (err) {
    console.log('hide');
    return '';
  }
};
