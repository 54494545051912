export const languages = [
  { label: "Amharic", value: "am" },
  { label: "German", value: "de" },
  { label: "Malayalam", value: "ml" },
  { label: "Slovak", value: "sk" },
  { label: "Arabic", value: "ar" },
  { label: "Greek", value: "el" },
  { label: "Maldivian", value: "dv" },
  { label: "Slovenian", value: "sl" },
  { label: "Armenian", value: "hy" },
  { label: "Gujarati", value: "gu" },
  { label: "Marathi", value: "mr" },
  { label: "Sorani Kurdish", value: "ckb" },
  { label: "Basque", value: "eu" },
  { label: "Haitian Creole", value: "ht" },
  { label: "Nepali", value: "ne" },
  { label: "Spanish", value: "es" },
  { label: "Bengali", value: "bn" },
  { label: "Hebrew", value: "iw" },
  { label: "Norwegian", value: "no" },
  { label: "Swedish", value: "sv" },
  { label: "Bosnian", value: "bs" },
  { label: "Hindi", value: "hi" },
  { label: "Oriya", value: "or" },
  { label: "Tagalog", value: "tl" },
  { label: "Bulgarian", value: "bg" },
  { label: "Latinized Hindi", value: "hi-Latn" },
  { label: "Panjabi", value: "pa" },
  { label: "Tamil", value: "ta" },
  { label: "Burmese", value: "my" },
  { label: "Hungarian", value: "hu" },
  { label: "Pashto", value: "ps" },
  { label: "Telugu", value: "te" },
  { label: "Croatian", value: "hr" },
  { label: "Icelandic", value: "is" },
  { label: "Persian", value: "fa" },
  { label: "Thai", value: "th" },
  { label: "Catalan", value: "ca" },
  { label: "Indonesian", value: "in" },
  { label: "Polish", value: "pl" },
  { label: "Tibetan", value: "bo" },
  { label: "Czech", value: "cs" },
  { label: "Italian", value: "it" },
  { label: "Portuguese", value: "pt" },
  { label: "Traditional Chinese", value: "zh-TW" },
  { label: "Danish", value: "da" },
  { label: "Japanese", value: "ja" },
  { label: "Romanian", value: "ro" },
  { label: "Turkish", value: "tr" },
  { label: "Dutch", value: "nl" },
  { label: "Kannada", value: "kn" },
  { label: "Russian", value: "ru" },
  { label: "Ukrainian", value: "uk" },
  { label: "English", value: "en" },
  { label: "Khmer", value: "km" },
  { label: "Serbian", value: "sr" },
  { label: "Urdu", value: "ur" },
  { label: "Estonian", value: "et" },
  { label: "Korean", value: "ko" },
  { label: "Simplified Chinese", value: "zh-CN" },
  { label: "Uyghur", value: "ug" },
  { label: "Finnish", value: "fi" },
  { label: "Lao", value: "lo" },
  { label: "Sindhi", value: "sd" },
  { label: "Vietnamese", value: "vi" },
  { label: "French", value: "fr" },
  { label: "Latvian", value: "lv" },
  { label: "Sinhala", value: "si" },
  { label: "Welsh", value: "cy" },
  { label: "Georgian", value: "ka" },
  { label: "Lithuanian", value: "lt" }
];
