import { Legend } from 'chart.js';
import React, { useState, useEffect, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Label, CartesianGrid } from 'recharts';

const ReviewLineChart = ({ data, height = 280,ylabel }) => {
  const parentRef = useRef(null);
  const [numericWidth, setNumericWidth] = useState(680);

  const maxYValue = Math.max(...data?.map((item) => item.y));
  const minYValue = Math.min(...data?.map((item) => item.y));

  const domainPadding = 5;

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const parentWidth = parentRef?.current?.offsetWidth ;
      setNumericWidth(parentWidth);
    });

    resizeObserver.observe(parentRef?.current);

    return () => resizeObserver.disconnect();
  }, []);

  const data1 = [
    {
      reviews: 12,
      date: '12/05/2024',
      "name": "Page A",
      "uv": 4000,
      "pv": 2400,
      "amt": 2400
    },
    {
      reviews: 121,
      date: '13/05/2024',
      "name": "Page B",
      "uv": 3000,
      "pv": 1398,
      "amt": 2210
    },
    {

      reviews: 14,
      date: '14/05/2024',
      "name": "Page C",
      "uv": 2000,
      "pv": 9800,
      "amt": 2290
    },
    {

      reviews: 16,
      date: '15/05/2024',
      "name": "Page D",
      "uv": 2780,
      "pv": 3908,
      "amt": 2000
    },
    {

      reviews: 20,
      date: '16/05/2024',
      "name": "Page E",
      "uv": 1890,
      "pv": 4800,
      "amt": 2181
    },
    {

      reviews: 21,
      date: '17/05/2024',
      "name": "Page F",
      "uv": 2390,
      "pv": 3800,
      "amt": 2500
    },
    {

      reviews: 43,
      date: '18/05/2024',
      "name": "Page G",
      "uv": 3490,
      "pv": 4300,
      "amt": 2100
    }
  ]

  return (
    <div ref={parentRef} style={{ width: '100%', height: '100%' }}>
      <LineChart
        width={numericWidth}
        height={height}
        data={data}
        margin={{ top: 20, right: 40, left: 10, bottom: 40 }}

      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          dataKey='date'
          dy={8}
          interval={data.length > 20 ? Math.ceil(data.length / 20) : 0}
          tick={{ fontSize: 9, fontWeight: 'bold', angle: -35 }}
        >
          <Label
            value='Timeline'
            offset={-25}
            position='insideBottom'
            fontWeight={500}
            fill='#222'
            fontSize={14}
          />
        </XAxis>
        <YAxis
          type='number'
          /* domain={[minYValue - domainPadding, maxYValue + domainPadding]} */
          tick={{ fontSize: 12 }}
          tickCount={6}
        >
          <Label
            value={ylabel}
            offset={-4}
            fontSize={16}
            position='insideLeft'
            angle={-90}
            fontWeight={500}
            fill='#222'
          />
        </YAxis>
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey={ylabel?.toLowerCase() || 'reviews'} stroke="#2E5CD2" />
        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
      </LineChart>
    </div>
  );
};

export default ReviewLineChart;
