import React, { useState, useEffect } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleQuantile } from 'd3-scale';
import styled from 'styled-components';
import LinearGradient from './LinearGradient.js';
/* import ReactTooltip from 'react-tooltip'; */
import { fontSize, padding, style, width } from '@mui/system';
import { Tooltip } from '@mui/material';
import PopUp from '../../Common/PopUp/PopUp.jsx';

const TooltipContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 50px;
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap; // Prevent tooltip text from wrapping
  z-index: 9999;
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.83);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Wrapper = styled.div`
  width: 100%;
  align-items: center;
  border-radius: 0px;
  border: 1px solid #d9d9d9;
`;

const MapContainer = styled.div`
 display:flex;
 flex-direction:row;
`;

const TextWrapper = styled.div`
  text-align: left;
  & > * {
    float: left;
    clear: both;
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  span {
    font: Open Sans;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 25px;
  }
`;

const Message = styled.div`
  color: #2a2929;
  text-align: left;
  font-family: 'Open Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Name = styled.div`
  color: #000;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const CancelButton = styled.span`
  cursor: pointer;
  float: right;
  margin: 20px 20px 0px 0px;

  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

/* import LinearGradient from './LinearGradient.js'; */

/**
 * Courtesy: https://rawgit.com/Anujarya300/bubble_maps/master/data/geography-data/india.topo.json
 * Looking topojson for other countries/world?
 * Visit: https://github.com/markmarkoh/datamaps
 */
const INDIA_TOPO_JSON = require('./india.topo.json');

const PROJECTION_CONFIG = {
  scale: 500,
  center: [78.9629, 22.5937], // always in [East Latitude, North Longitude]
};

// Red Variants
const COLOR_RANGE = [
  '#ffedea',
  '#ffcec5',
  '#ffad9f',
  '#ff8a75',
  '#ff5533',
  '#e2492d',
  '#be3d26',
  '#9a311f',
  '#782618',
];

const DEFAULT_COLOR = '#EEE';

const getRandomInt = () => {
  return parseInt(Math.random() * 100);
};

const geographyStyle = {
  default: {
    outline: 'none',
  },
  hover: {
    fill: '#ccc',
    transition: 'all 250ms',
    outline: 'none',
  },
  pressed: {
    outline: 'none',
  },
};

const stateNameToId = {
  'Andhra Pradesh': 'AP',
  'Arunachal Pradesh': 'AR',
  'Assam': 'AS',
  'Bihar': 'BR',
  'Chhattisgarh': 'CT',
  'Goa': 'GA',
  'Gujarat': 'GJ',
  'Haryana': 'HR',
  'Himachal Pradesh': 'HP',
  'Jharkhand': 'JH',
  'Karnataka': 'KA',
  'Kerala': 'KL',
  'Madhya Pradesh': 'MP',
  'Maharashtra': 'MH',
  'Manipur': 'MN',
  'Meghalaya': 'ML',
  'Mizoram': 'MZ',
  'Nagaland': 'NL',
  'Odisha': 'OD',
  'Punjab': 'PB',
  'Rajasthan': 'RJ',
  'Sikkim': 'SK',
  'Tamil Nadu': 'TN',
  'Telangana': 'TS',
  'Tripura': 'TR',
  'Uttarakhand': 'UK',
  'Uttar Pradesh': 'UP',
  'West Bengal': 'WB',
  'Andaman and Nicobar Islands': 'AN',
  'Chandigarh': 'CH',
  'Dadra and Nagar Haveli': 'DN',
  'Daman and Diu': 'DD',
  'Delhi': 'DL',
  'Jammu and Kashmir': 'JK',
  'Ladakh': 'LA',
  'Lakshadweep': 'LD',
  'Puducherry': 'PY',
};

const transformData = (data) => {
  return Object.entries(data).map(([state, value]) => ({
    id: stateNameToId[state] || state,
    state,
    value : value?.count,
    topics: value.topics
  }));
};

function MapChart({ text, colorRange, mapData, type = '' }) {
  const [tooltipContent, setTooltipContent] = useState('');
  const [data, setData] = useState([]);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [geodata, setGeoData] = useState(null)

  const gradientData = {
    fromColor: colorRange[0],
    toColor: colorRange[colorRange.length - 1],
    min: 0,
    max: data.reduce((max, item) => (item.value > max ? item.value : max), 0),
  };

  
  useEffect(() => {
    if (mapData) {
      setData(transformData(mapData));
    }
  }, [mapData]);
  
  const colorScale = scaleQuantile()
    .domain(data.map((d) => d.value))
    .range(colorRange);


  const onMouseEnter = (geo, current = { value: 'NA' }) => {
    return (event) => {
      setTooltipContent(`${geo.properties.name}: ${current.value}`);
      setTooltipPosition({ x: event.clientX, y: event.clientY });
    };
  };

  const onMouseLeave = () => {
    setTooltipContent('');
  };

   /* const onChangeButtonClick = () => {
    setData(getHeatMapData());
  }; */
  
  const showPopup = (geo) =>{
    const current = data.find((s) => s.id === geo.id)
    if(current){
      setIsPopupOpen(true)
      setGeoData(current)
    }
  }

  return (
    <Wrapper>
      <MapContainer>
        {tooltipContent && (
          <TooltipContainer
            style={{
              top: tooltipPosition.y + 10,
              left: tooltipPosition.x + 10,
              width: '187px',
            }}
          >
            {tooltipContent}
          </TooltipContainer>
        )}  
      <ComposableMap
        projectionConfig={PROJECTION_CONFIG}
        projection='geoMercator'
        width={600}
        height={400}
        data-tip=''
      >
        <Geographies geography={INDIA_TOPO_JSON}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const current = data?.find((s) => s.id === geo.id);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={current ? colorScale(current.value) : DEFAULT_COLOR}
                  style={geographyStyle}
                  onMouseEnter={onMouseEnter(geo, current)}
                  onMouseLeave={onMouseLeave}
                  onClick={() => showPopup(geo)}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      <LinearGradient
        colorRange={colorRange}
        min={gradientData.min}
        max={gradientData.max}
      />
      </MapContainer>
      <Text>{text}</Text>
      {
         isPopupOpen && geodata?.topics && (
          <PopUp onClose={() => setIsPopupOpen(false)}>
            <TextWrapper>
              <span>{`${geodata?.state} - ${data?.find((s) => s.id === geodata.id)?.value} Reviews`}</span>
              <span>{`Top ${type} Discussions`}</span>
                {Object.entries(geodata.topics).map(([key, value]) => (
                  <Message key={key}>
                    <strong>{key}:</strong> {value}
                  </Message>
                ))}
              <br />
              <CancelButton onClick={() => {
                setIsPopupOpen(false)
                setGeoData(null)
                }}>
                Close
              </CancelButton>
            </TextWrapper>
          </PopUp>
        ) 
      }

    </Wrapper>
  );
}



export default MapChart;