




import React from 'react';
import styled from 'styled-components';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: row;
`;

const COLORS = ['#57E188', '#F95D54', '#FFCA63', '#6F62FE', '#6F62FE'];

const renderCustomizedLabel =
  (data, type) =>
    ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      if (percent <= 0.01) {
        return null;
      }
      const radius = innerRadius + (outerRadius - innerRadius) * 2;
      const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
      const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

      const angle = midAngle > 180 ? midAngle - 360 : midAngle;

      let textAnchor;
      if (angle > 0) {
        textAnchor = 'start';
      } else {
        textAnchor = 'end';
      }

      return (
        <>
          <text
            x={x}
            y={y}
            fill='black'
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline='central'
            fontSize='14px'
          >
            {`${data[index].name} - ${data[index].value}${type === 'percentage' ? '%' : ''
              }`}
          </text>
        </>
      );
    };

const DonutChart = ({ data, align = "right", sentiment, type }) => {
  // let finalData = data.filter(row => row.value > 0);
  let finalData = data
  .map(row => ({ ...row, value: parseFloat(row.value.toFixed(2)) }))
  .filter(row => row.value > 0);
  const sortedData = finalData.sort((a, b) => b.value - a.value);

  const tooltipFormatter = (value) => {
    return ` ${ typeof value === 'number' && value !== 0 && value}`;
  };





  return (
    <Wrapper className="pdf-page" style={{ pageBreakInside: 'avoid' }}>
      <PieChart width={450} height={250}>
        <Pie
          data={sortedData}
          dataKey='value'
          cx='50%'
          cy='50%'
          startAngle={90}
          endAngle={-270}
          innerRadius={80} // Adjust the size of the hole
          outerRadius={100}
          //  label={renderCustomizedLabel(sortedData, type)}
        >
          {sortedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Pie>
        <Legend layout='vertical' verticalAlign='middle' align="right" />
        <Tooltip formatter={tooltipFormatter} />
      </PieChart>
      {/*  <div style={{display:'inline'}}>
    <SquareWrapper/>
    <span>Positive</span>    
    </div>  */}
    </Wrapper>
  );
};

export default DonutChart;



