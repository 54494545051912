// CustomNotification.js

import React from 'react';

const CustomNotification = ({ message, onConfirm }) => (
  <div>
    <p>{message}</p>
    <button onClick={onConfirm}>Confirm</button>
  </div>
);

export default CustomNotification;
