import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';

export const Options_UL = styled.ul`
    padding: 8px 0;
    background-color: #fff;
    z-index: 3;
    width: 100%;
    box-shadow: 0px 0px 8px rgba(85, 85, 85, 0.25);
    border-radius: 15px;
    overflow-y: scroll;
    right: 0px;

    & .active-option {
      color: #353cf7;
    }

    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(85, 85, 85, 0.2);
      border-radius: 10px;
    }
  }
`;

export const Options_LI = styled.li`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 15px;

  font-weight: 500;
  font-size: 16px;
  color: #707070;
  &:hover {
    background-color: #f2f2f2;
  }
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 30px;
  height: 20px;
  border: 1px solid rgba(152, 152, 152, 0.24);
  margin: 0 10px;
  border-radius: 50%;
  color: #fff;
  background: #fff;

  ${({ active }) => active === 'true' && 'background: #353cf7'}
`;

export const DeletePopupWrapper = styled.div`
  line-height: 1.3rem;

  & svg {
    width: 60px;
    height: 60px;
  }

  & > p:nth-child(2) {
    color: #fc6758;
  }

  & > p:nth-child(3) {
    //User name that will be displayed in the popup window
    span {
      color: #222;
    }
  }

  // Cancel and Delete button containers
  & > div {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    & > button {
      width: 200px;
      height: 45px;
      margin: 0 10px;
    }
    & > button:nth-child(1) {
      background-color: #fff;
      color: #222;
      width: min-content;
      margin-right: 30px;
    }
  }
`;

export const LogoutPopUP = styled.div`
  max-width: 150px;
  position: absolute;
  top: 55px;
  right: 18px;

  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  z-index: 10;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 15px 0px;
  gap: 5px;

  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;

  & > div {
    padding: 0 15px;
  }

  & > .userPic {
    align-self: center;
    & > div {
      width: 52px;
      height: 52px;
    }
  }

  & > .userName {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > .userName + div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > .line {
    height: 1px;
    background-color: #c7c7c7;
    margin: 5px;
  }

  & > .myProfile_logout {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    & > svg {
      width: 16px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Triangle = styled.p`
  position: absolute;
  top: 25px;
  right: 40px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #fff;
  z-index: 11;
`;
