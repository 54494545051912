import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import NewSideBar from '../../components/NewSideBar/NewSideBar';
import ChatterTrackerBuilder from './MainComponent/ChatterTrackerBuilder';
import { useParams } from 'react-router-dom';
import ChatterHomePage from './MainComponent/ChatterTrackerHome/HomePage';
import Preview from './MainComponent/ChatterPreview/Preview';
import { chatterList } from '../../store/redux/slices/chatterSlice';

import { useSelector,useDispatch } from 'react-redux';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 1fr;
  height: calc(100vh - 60px);
  overflow: hidden;
`;

const ChatterTracker = () => {
  const { component } = useParams();
  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [chatterName, setChatterName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedReport,setSelectedReport] = useState(null);
  
  const dispatch = useDispatch();

  const {chatterListDate}  =
  useSelector((state) => state.chatterTracker) || [];

    if (chatterListDate === null) {
      dispatch(chatterList());
    }
;

  /* const subTabs = [
    {
      id: '1a',
      val: 'New Chatter',
      type: 'add',
      path: '/chatter/create',
    },
  ]; */

  /* const subTabs = [] */
  const activeSubTabs = [];
  const hibernatedSubTabs = [];

  const TabScreen = {
    home: <ChatterHomePage setSelectedReport={setSelectedReport} />,
    create: (
      <ChatterTrackerBuilder
        setLoading={setLoading}
        setPreviewData={setPreviewData}
        setChatterName={setChatterName}
        setstartDate={setStartDate}
        setEndDate={setEndDate}
      />
    ),
    view: (
      <Preview
        loading={loading}
        previewData={previewData}
        chatterName={chatterName}
        selectedReport = {selectedReport}
        startDate={startDate}
        endDate={endDate}
      />
    ),
  };

  chatterListDate?.forEach((chatter) => {
    TabScreen[`job_${chatter.job_id}`] = <Preview job_id={chatter.job_id} />;
    /* subTabs.push({
      id: chatter.job_id,
      val: chatter.chatter_title,
      path: `/chatter/job_${chatter.job_id}`,
    }); */
    const subTab = {
      id: chatter.job_id,
      val: chatter.chatter_title,
      path: `/chatter/job_${chatter.job_id}`,
      channel:chatter.channel,
    };
    if (chatter.is_active === 0) {
      activeSubTabs.push(subTab);
    } else if(chatter.is_active === 1) {
      hibernatedSubTabs.push(subTab);
    }
  });
;

  const tabs = [
    {
      id: 1,
      val: 'Active',
      /* path: '/chatter/home', */
      subTabs: activeSubTabs,
    },
    {
      id:1,
      val: 'Hibernated',
      subTabs: hibernatedSubTabs,
    }
  ];
  //To set active tab of the sidebar
  const generateActiveTab = () => {
    if (component === 'home') {
      return 1;
    } else if (component === 'create') {
      return { tab: 1, subTab: '1a' };
    } else if (component === 'view' ) {
      return { tab: 1, subTab: '1a' };
    }
   /*  for (let i = 0; i < subTabs.length; i++) {
      if (component === `job_${subTabs[i].id}`) {
        return { tab: 1, subTab: subTabs[i].id };
      }
    } */
    for (let i = 0; i < activeSubTabs.length; i++) {
      if (component === `job_${activeSubTabs[i].id}`) {
        return { tab: 1, subTab: activeSubTabs[i].id };
      }
    }
    for (let i = 0; i < hibernatedSubTabs.length; i++) {
      if (component === `job_${hibernatedSubTabs[i].id}`) {
        return { tab: 2, subTab: hibernatedSubTabs[i].id };
      }
    }
  };


  return (
    <Wrapper>
      <NewSideBar
        headerName='Chatter Tracker'
        tabs={tabs}
        initialActiveTab={generateActiveTab()}
        buttonTitle={'New Chatter'}
      />
      {TabScreen[component]}
    </Wrapper>
  );
};

export default ChatterTracker;






