import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SmallSelect from '../Select/SmallSelect';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
`;

const Title = styled.div`
  ${'' /* color: #636363; */}
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  width: ${(props) => props.graphWidth};
  margin: ${(props) => props.margin};
  ${'' /* justify-content: center; */}
  align-items: center;
`;

const Span = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  align-self: flex-end;
  margin-right: 10px;
  margin-bottom: -4px;
`;

const GraphHOC = ({
  title = '',
  renderScreen = () => {},
  options = [],
  graphWidth = '100%',
  margin = '0px',
  startDate = '',
  endDate = '',
  option,
  alignLeft,
  hideDropdown
}) => {
  const [selectedGraph, setSelectedGraph] = useState(options[0]);

  useEffect(() => {
    if (option) {
      setSelectedGraph(option);
    }else{
      setSelectedGraph(options[0]);
    }
  }, [option]);
  const handleSelectedGraph = (opt) => {
    setSelectedGraph(opt);
  };
  
  return (
    <Wrapper>
      <Title>
        <p>{title}</p>
        <div style={{display: hideDropdown === true ? 'none' : 'flex'}}>
          <SmallSelect 
            onChange={handleSelectedGraph}
            options={options} 
            option={option || options[0]} 
            hideDropdown = {hideDropdown}
          />
        </div>
      </Title>
      <MainContainer graphWidth={graphWidth} margin={margin}>
        {renderScreen[selectedGraph]}
      </MainContainer>
      {startDate && (
        <Span>
          This data is observed from {startDate} to {endDate}
        </Span>
      )}
    </Wrapper>
  );
};

export default GraphHOC;
